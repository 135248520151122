import { useEffect, useState } from "react";
import FooterContainer from "src/components/footer/footer";
// import ABOUTusSEO from "src/components/SEOComponents/aboutusSEO";
import SEOContents from "src/components/SEOContents";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import { Helmet } from "react-helmet";

function AboutUFun88(props: any) {
    const [sitename, setsitename] = useState('')
    const { app: { brandId } } = props;

    let domainURL = 'https://www.yolo247.co';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.com';
    } 
    else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
    }
    else if (brandId === 46) {
		domainURL = 'https://www.fun88.site';
	}
	else if (brandId === 47) {
		domainURL = 'https://www.gofun88.in';
	}

    const domain = brandId === 31 || brandId === 32 ? 'Yolo247' : (brandId == 39 || brandId == 40) ? 'Fomo7' : brandId === 33 ? 'Baazi Adda' :brandId === 46 ? 'Fun88': brandId === 47 ? 'Fun88':'Betdaily';

    useEffect(() => {
        window.scrollTo(0, 0)

        switch (brandId) {
            case 31:
                setsitename('yolo247')
                break;
            case 32:
                setsitename('yolo247')
                break;
            case 33:
                setsitename('Baazi Adda')
                break;
            case 34:
                setsitename('betdaily')
                break;
            case 39:
                setsitename('FOMO7')
                break;
            case 40:
                setsitename('FOMO7')
                break;
            case 46:
                setsitename('FUN88')
                break;
            case 47:
                setsitename('FUN88')
                break;
            default:
                setsitename('')
                break;
        }
    }, [])

    return (
        <>
            <h1 className="aboutHeading">About Us</h1>
            <p>{sitename} is a leading online gambling platform that offers a variety of fun and exciting gaming products from sportsbook, exchange, in-play sports betting, live casino, slot games, instant games and more! </p>

            <h2>What we do?</h2>
            <ul>
                <li>We provide betting IDs through WhatsApp for both casino & sports, with 24x7 customer support. </li>
                <li>We offer 700+ online games, which are available at your fingertips. </li>
                <li>We provide odds for all the national, regional & international sporting events. </li>
            </ul>

            <h2>A Credible Online Gambling ID Provider in India</h2>
            <p>FUN88 is a fully registered gambling and entertainment company. Being certified and governed by credible gambling bodies assures all members that join and play with FUN88 confirms to standards set of regulation in internet gambling.</p>
            <p>To offer our members the latest online betting technology and the best gaming experience, FUN88 partners with a variety of prominent gaming platforms. </p>

            <h2>Our Services</h2>
            {/* <p>We provide 700+ casino games and <a href={`${domainURL}/betting-exchange`}>sports betting</a> options.</p> */}
            <p>We provide 700+ casino games and sports betting options.</p>

            <h3>Casino:</h3>
            <ul>
                <li>Live Casino</li>
                <li>Instant Games</li>
                <li>Slots</li>
            </ul>

            <h3>Sports Betting:</h3>
            <ul>
                <li>Exchange</li>
                <li>Sportsbook</li>
            </ul>

            <h3>Our Bonuses</h3>
            <ul>
                <li>Welcome Bonus - 388%</li>
                <li>Redeposit Bonus - 5%</li>
                <li>Cashback Bonus - 5%</li>
                <li>Referral Bonus - 24%</li>
            </ul>

            <h3>Our Policies</h3>
            <ul>
                <li>KYC Policy</li>
                <li>Anti-Money Laundering Policy</li>
                <li>Self-Exclusion Policy</li>
                <li>Responsible Gaming</li>
                <li>Privacy Policy</li>
            </ul>

            <p>We have partnered with various elite clubs, teams, and franchises across the world for various sporting events. We believe in keeping the fun alive, amid all the things that we do in our daily lives.</p>
            <p>Welcome to the world of infinite fun. Welcome to FUN88.</p>

            <p><strong>Let's begin today!</strong></p>
            <p><a href={`${domainURL}/login`}>Start winning now.</a></p>
        </>
    )
}

export default withTheme(withAppContext(AboutUFun88));
