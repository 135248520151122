import Intl from "./common/Intl";
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import config from "src/config/config";
import styled, { css, withTheme } from 'styled-components';
import { MainInfoWrapper } from 'src/components/Loyalty/index';
import { withAppContext } from "src/store/initAppContext";
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import { ifProp, theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import { useNavigate } from "react-router-dom";
import SEOContents from "./SEOContents";
import { SRTM } from 'src/util/sportradarEvent';
// import Baccaratpromo from "src/pages/pr omotions/EvolutionCasinoCupPromo";
import EvoBaccaratBetWin from "src/pages/promotions/EvolutionBaccaratBetWin";
import WcCashbackBonanza from "src/pages/promotions/dailyDepositBonus";
import PlatinumRush from "src/pages/promotions/PlatinumRush";
import WCbetBoost from "src/pages/promotions/WCbetBoost";
import Catch99_s2 from "src/pages/promotions/catch99_2";
import { Helmet } from "react-helmet";
import EvoCrazyPachinkoPromo from "src/pages/promotions/EvoCrazyPachinkoPromo";
import BblBonusInner from "src/pages/promotions/BblBonusInner";
import JetXCelestialRush from "src/pages/promotions/JetXCelestialRush";
import AviatrixDoubleDhamaka from "src/pages/promotions/AviatrixDoubleDhamaka";
import CricketCashbackMarathon from "src/pages/promotions/CricketCashbackMarathon";
import CrazyTimeEpicBonanza from "src/pages/promotions/crazyTimeEpicBonanza";
import AviatorHighFlyer from "src/pages/promotions/AviatorHighFlyer";
import SmartsoftSpinPromo from "src/pages/promotions/SmartsoftSpinPromo";
import IPLpowerPlayBonus from "src/pages/promotions/IPLpowerPlayBonus";
import IplBettingDhamaka from 'src/pages/promotions/ipl-promo';
import AviatorSkyBoundMiles from "src/pages/promotions/AviatorSkyBoundMiles";
import AviatrixFBEPromo from "src/pages/promotions/AviatrixFBEPromo";
import IndianGalaxsysLeague from "src/pages/promotions/IndianGalaxsysLeague";
import EvolutionCasinoCupPromotion from "src/pages/promotions/EvolutionCasinoCupPromotion";
import DoubleCashBackPromo from "src/pages/promotions/DoubleCashBackPromo";

import FomoRefillBonus from "src/pages/promotions/FomoRefillBonus";
import FomoLossBackBonus from "src/pages/promotions/FomoLossBackBonus";
import FomoWReferralBonus from "src/pages/promotions/FomoWReferralBonus";
import FomoWorldCupLossBackExch from "src/pages/promotions/FomoWorldCupLossBackExch";
import FomoWorldCupGoIndiaGoJackpot from "src/pages/promotions/FomoWorldCupGoIndiaGoJackpot";
import FomoAviatorWingsofAdventure from "src/pages/promotions/FomoAviatorWingsofAdventure";
import FomoEvolutionCasinoChallenge from "src/pages/promotions/FomoEvolutionCasinoChallenge";
import FomoAviatrixFlyHigh from "src/pages/promotions/FomoAviatrixFlyHigh";
import FomoWorldCupdailyDespositBonus from "src/pages/promotions/FomoWorldCupdailyDespositBonus";
import WorldCupLossBack from "src/pages/promotions/WorldCupLossBack";
import WorldCupDoubleCashBack from "src/pages/promotions/WorldCupDoubleCashBack";
import WorldCupEvolutionCasinoCup from "src/pages/promotions/WorldCupEvolutionCasinoCup";
import WorldCupDoubleCashBackBazzi from "src/pages/promotions/WorldCupDoubleCashBackBazzi";
import EvolutionCasinoTourneyBazzi from "src/pages/promotions/EvolutionCasinoTourneyBazzi";
import DailyRewardsBazzi from "src/pages/promotions/DailyRewardsBazzi";
import SmartSoftJetxCelestialPromo from "src/pages/promotions/SmartSoftJetxCelestialPromo";
import MinesLegendTrophy from "src/pages/promotions/MinesLegendTrophy";
import CaribbeanRoyalBonus from "src/pages/promotions/CaribbeanRoyalBonus";
import EvolutionRouletteCup from "src/pages/promotions/EvolutionRouletteCup";
import AviatrixFlyAndPlayPromo from "src/pages/promotions/AviatrixFlyAndPlayPromo";
import AviatorRainPromo from "src/pages/promotions/AviatorRainPromo";
import JetSetWithJetXPromo from "src/pages/promotions/JetSetWithJetXPromo";
import WomensWorldCupPromo from "src/pages/promotions/WomensWorldCupPromo";
import FomoWelcomeBonus from "src/pages/promotions/FomoWelcomeBonus";
import WelcomeBonusFun from "src/pages/promotions/WelcomeBonusFun";
import CashbackBonusf88 from '../pages/promotions/CashbackF88'
import RefillBonusf88 from '../pages/promotions/RefilBonusf88'
import ReferralBonusFunf88 from "src/pages/promotions/RefferalBonusf88";
import AviatrixJetToCashPromoYolo from "src/pages/promotions/AviatrixJetToCashPromoYolo";
import AviatrixJetToCashPromo from "src/pages/promotions/AviatrixJetToCashPromo";
import BigBashBigCashPromo from "src/pages/promotions/BigBashBigCashPromo";
import BigBashBigCashPromoFomo from "src/pages/promotions/BigBashBigCashPromoFomo";
import AviatrixJetToCashPromoFun88 from "src/pages/promotions/AviatrixJetToCashPromoFun88";
import BigBashBigCashPromoFun from "src/pages/promotions/BigBashBigCashPromoFun";
import getSchemaData from "./schema";
// import { RefillBonusSEO, RefillBonusSEOBD } from "./SEOComponents/RefillBonusSEO";
// import { RefferalBonusSEO, RefferalBonusSEOBD } from "./SEOComponents/RefferalBonusSEO";


interface StyleProps {
  active: any
}

const structuredData = (brandId, pageId) => {
	let domainURL = 'https://www.yolo247.co';
  
  if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.baaziadda.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }
  else if (brandId === 39) {
    domainURL = 'https://www.fomo7.com';
  } else if (brandId === 40) {
    domainURL = 'https://www.fomo7.xyz';
  } 
  else if (brandId === 46) {
    domainURL = 'https://www.fun88.site';
  }
  else if (brandId === 47) {
    domainURL = 'https://www.gofun88.in';
  }
  
  let label = pageId;
  if(pageId === 'welcome-bonus'){
    label = 'Welcome Bonus 400%'
  }
  else if(pageId === 'refill-bonus'){
    label = 'Refill Bonus'
  }
  else if(pageId === 'cashback-bonus'){
    label = 'Cashback Bonus'
  }

const otherschemaData = getSchemaData(pageId);

  let itemListElement = [
    {
      "@type": "ListItem",
      "item": `${domainURL}`,
      "name": "Homepage",
      "position": 1
    },
    {
      "@type": "ListItem",
      "item": `${domainURL}/promotions/`,
      "name": "Promotions page",
      "position": 2
    }
  ]

  if (pageId !== "promotions") {
    itemListElement = [...itemListElement, {
      "@type": "ListItem",
      "item": `${domainURL}/promotions/${pageId}`,
      "name": `${label}`,
      "position": 3
    }]
  }

  const data = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": itemListElement,
      "name": "Breadcrumbs"
    },
    ...otherschemaData
  ];
  
	return JSON.stringify(data)
}

const BonusMain = (props) => {
  const { app: {
    brandId
  }, theme } = props;

  const { config: {
    shoeIPLpowerPlay,
    showReferralBonus = true,
    showWelcomeBonus = true,
    showRefillBonus = true,
    showCashbackBonus = true,
    showAviatorBonus = false,
    showBBLBonus = true,
    showSingWithYolo = true,
    jetxpromo = true,
    showIplPromo,
    showCrazyCrashPromo = false,
    IPLCasinoCarnivalPromotion = true,
    pslpromo = true,
    showTermsOnYolo,
    showEvolutionpromo,
    showFunkytimePromo,
    showDailyDepBonusPromo,
    showEvoCasinoCupPromo,
    showWCBonusPromo,
    showAsiaCupBonanzaPromo,
    showPlatinumRush,
    showCatch99Bonus,
    showJetXCelestialRush,
    showCrazyPachinkoPromo,
    showAviatrixPromo,
    showcircketCashbackMarathon,
    showCrazyTimePromo,
    showAviatorHighFlyerPromo,
    showSmartsoft,
    showAviatorSkyBoundMiles,
    showAviatrixFBEPromo,
    showIndianGalaxysLeague,
    showEvolutionCasionCup,
    showDoubleCashBack,
    showFomoWelcomeBonus,
    showFomoRefillBonus,
    showFomoLossBackBonus,
		showFomoReferralBonus,
    showFomoWcLossBackExchPromo,
    showFomoGoIndiaGoJackpotPromo,
    showFomoAviatorWingsofAdventurePromo,
    showFomoEvolutionCasinoChallengePromo,
    showFomoAviatrixFlyHighPromo,
    showWcDailyDepositBonusPromo,
    showWorldCupLossbackYolo,
    showWorldCupDoubleCashbackBonus,
    showWorldCupEvolutionCasinoCup,
    showWorldCupDoubleCashBackBazzi,
    showEvolutionCasinoTourneyBazzi,
    showDailyRewardsBazzi,
    showSmartSoftJetxCelestial,
    showMinesLegendTrophyPromo,
    showCaribbeanLeagueBonusPromo,
    showEvolutionRouletteCupPromo,
		showAviatrixFlyAndPlayPromo,
    showAviatrixJetToCrash,
    showAviatorRainPromo,
    showJetSetWithJetXPromo,
    showWomensWorldCupPromo,
    showBigBashBigCashPromo
  } } = theme;

  const { pathname } = useLocation();
  let currPage = pathname.split("/").pop();

  const [active, setactive] = useState("first");
  const [seoCont, setseoCont] = useState("promotions");
  const [activeState, setactiveState] = useState(false);

  const navigate = useNavigate()

  const Language = cookieUtil.get(ECookieName.COOKIE_LANG)

  useEffect(() => {
    SRTM.init(brandId)
  }, [])


  useEffect(() => {
    window.scrollTo(0, 0);
    switch (currPage) {
      case "welcome-bonus":
      case "Welcome%20Bonus":
        setactive("first");
        setseoCont("welcomeBonus");
        tabClickHandler("first");
        break;
      case "refill-bonus":
      case "Refill%20Bonus":
        setactive("second");
        setseoCont("refillBonus");
        tabClickHandler("second");
        break;
      case "Cashback%20Bonus":
      case "cashback-bonus":
        setactive("third");
        setseoCont("cashbackBonus");
        tabClickHandler("third");
        break;
      case "Refer%20Your%20Friend%20and%20get%20rewarded":
      case "referral-bonus":
        setactive("four");
        setseoCont("referralBonus");
        tabClickHandler("four");
        break;
      case "aviator-bonus":
        setactive("fifth");
        setseoCont("promotions");
        tabClickHandler("fifth");
        break;
      // case "big-bash":
      //   setactive("sixth");
      //   setseoCont("promotions");
      //   break;
      case "arjith_promo":
        setactive("seventh");
        setseoCont("promotions");
        tabClickHandler("seventh");
        break;
      case "jetx-tournament":
        setactive("eight");
        setseoCont("promotions");
        tabClickHandler("eight");
        break;
      case "crazyCrash":
        setactive("nine");
        setseoCont("promotions");
        tabClickHandler("nine");
        break;
      case "psl-promo":
        setactive("tenth");
        setseoCont("promotions");
        tabClickHandler("tenth");
        break;
      case 'ipl-betting-dhamaka':
        setactive("eleven");
        setseoCont("promotions");
        tabClickHandler("eleven");
        break;
      // case "ipl-casino-carnival":
      //   setactive("twelve");
      //   setseoCont("promotions");
      //   break;
      case "evolution-first-bet":
        setactive("thirteen");
        setseoCont("promotions");
        tabClickHandler("thirteen");
        break;

      // case "funkytime":
      //   setactive("fourteen");
      //   setseoCont("promotions");
      //   break;
      case "worldcup-cashback-bonanza":
        setactive("fifteen");
        setseoCont("promotions");
        tabClickHandler("fifteen");
        break;
      case "asia-cup":
        setactive("sixteen");
        setseoCont("promotions");
        tabClickHandler("sixteen");
        break;
      case "evo-baccarat":
        setactive("twenty");
        setseoCont("promotions");
        tabClickHandler("twenty");
        break;
      case "platinum-rush":
        setactive("seventeen");
        setseoCont("promotions");
        tabClickHandler("seventeen");
        break;
      case "worldcup-betboost":
        setactive("eighteen");
        setseoCont("promotions");
        tabClickHandler("eighteen");
        break;
      case "catch99-s2":
        setactive("nineteen");
        setseoCont("promotions");
        tabClickHandler("nineteen");
        break;
      case "evolution-crazy-pachinko":
        setactive("twentyone");
        setseoCont("promotions");
        tabClickHandler("twentyone");
        break;
      case "jetx-celestial-rush":
        setactive("twentytwo");
        setseoCont("promotions");
        tabClickHandler("twentytwo");
        break;
      case "cricket-cashback-marathon":
        setactive("twentyfour");
        setseoCont("promotions");
        tabClickHandler("twentyfour");
        break;
      case "crazytime-epic-bonanza":
        setactive("twentyfive");
        setseoCont("promotions");
        tabClickHandler("twentyfive");
        break;
      case "aviator-high-flyer":
        setactive("twentysix");
        setseoCont("promotions");
        tabClickHandler("twentysix");
        break;
      // case "smartsoft-spin-and-win":
      //   setactive("twentyseven");
      //   setseoCont("promotions");
      //   tabClickHandler("twentyseven");
      //   break;

        case "IPL-Betting-dhamaka-Power-Play-Bonus":
        setactive("thirty");
        setseoCont("promotions");
        tabClickHandler("thirty");
        
        break;
      case "aviator-sky-bound-miles":
        setactive("_thirty");
        setseoCont("promotions");
        tabClickHandler("_thirty");
        break;
      case "aviatrix-free-bets-extravaganza":
        setactive("thirtyone");
        setseoCont("promotions");
        tabClickHandler("thirtyone");
        break;
      case "indian-galaxsys-league":
        setactive("thirtytwo");
        setseoCont("promotions");
        tabClickHandler("thirtytwo");
        break;  
      case "evolution-casino-cup":
        setactive("twentyEight");
        setseoCont("promotions");
        tabClickHandler("twentyEight");
        break;
      case "double-cashback":
        setactive("twentyNine");
        setseoCont("promotions");
        tabClickHandler("twentyNine");
        break;
      case "wc-LossBack-Exchange":
        setactive("thirtyNine");
        setseoCont("promotions");
        tabClickHandler("thirtyNine");
        break;
      case "wc-goIndia-goJackpot":
        setactive("thirtySeven");
        setseoCont("promotions");
        tabClickHandler("thirtySeven");
        break;
      case "aviator-wingsOfAdventure":
        setactive("thirtyThree");
        setseoCont("promotions");
        tabClickHandler("thirtyThree");
        break;
      case "evolution-casino-challenge":
        setactive("fortyOne");
        setseoCont("promotions");
        tabClickHandler("fortyOne");
        break;
      case "aviatrix-fiyHigh":
        setactive("fortyNine");
        setseoCont("promotions");
        tabClickHandler("fortyNine");
        break;
      case "wc-dailyDeposit-bonus":
        setactive("fortySeven");
        setseoCont("promotions");
        tabClickHandler("fortySeven");
        break;
      case "wc-lossback":
        setactive("_thirtyNine");
        setseoCont("promotions");
        tabClickHandler("_thirtyNine");
        break;
      case "wc-doubleCashback":
        setactive("_forty");
        setseoCont("promotions");
        tabClickHandler("_forty");
        break;
      case "wc-double-Cashback":
        setactive("_Sixtyforty");
        setseoCont("promotions");
        tabClickHandler("_Sixtyforty");
        break;
      case "evolution-casino-tourney":
        setactive("_Sixtyfive");
        setseoCont("promotions");
        tabClickHandler("_Sixtyfive");
        break;
      case "daily-rewards":
        setactive("_SixtyNine");
        setseoCont("promotions");
        tabClickHandler("_SixtyNine");
        break;
      case "wc-evolutionCasinoCup":
        setactive("_fortyOne");
        setseoCont("promotions");
        tabClickHandler("_fortyOne");
        break;
      case "smartsoft-spin-and-win":
        setactive("fourtytwo");
        setseoCont("promotions");
        tabClickHandler("fourtytwo");
        break;
      case "mines-legend-trophy":
        setactive("fourtyNine");
        setseoCont("promotions");
        tabClickHandler("fourtyNine");
        break;
      case "caribbean-premier-league":
        setactive("fifty");
        setseoCont("promotions");
        tabClickHandler("fifty");
        break;
      case "evolution-roulette-cup":
        setactive("fiftyOne");
        setseoCont("promotions");
        tabClickHandler("fiftyOne");
        break;
      case "aviatrix-fly-and-play":
        setactive("fiftyTwo");
        setseoCont("promotions");
        tabClickHandler("fiftyTwo");
        break;
      case "aviator-rain":
        setactive("fiftyThree");
        setseoCont("promotions");
        tabClickHandler("fiftyThree");
        break;
      case "jet-set-with-jetx":
        setactive("fiftyFour");
        setseoCont("promotions");
        tabClickHandler("fiftyFour");
        break;
      case "womens-world-cup":
        setactive("fiftyFive");
        setseoCont("promotions");
        tabClickHandler("fiftyFive");
        break;
      case "aviatrix-jet-to-cash":
        setactive("fiftySix");
        setseoCont("promotions");
        tabClickHandler("fiftySix");
        break;
      case "big-bash-big-cash":
        setactive("fiftySeveen");
        setseoCont("promotions");
        tabClickHandler("fiftySeveen");
        break;
      default:
        setactive("first");
        setseoCont("promotions");
        tabClickHandler("first");
    }
  }, [currPage]);


  //add the image paths here according to the state value
  const activeImageMap = {
    first: theme.image.casinoBannerWelcomeImg,
    second: theme.image.BonusBannerRefillBG,
    third: theme.image.BonusCashbackBG,
    four: theme.image.referalBonus,
    fifth: theme.image.aviatorBonus,
    // sixth: Language === "hindi-ind" ? theme.image.BBLMainPromotionBannerHind : theme.image.BBLMainPromotionBannerEng,
    seventh: Language === "hindi-ind" ? theme.image.singwithyoloH : theme.image.singwithyolo,
    eight: Language === "hindi-ind" ? theme.image.jetxmain : theme.image.jetxmain,
    nine: Language === "hindi-ind" ? theme.image.crazyCrashChallengePromoBanner : theme.image.crazyCrashChallengePromoBanner,
    tenth: theme.image.pslpromo,
    eleven: theme.image.iplinnerbg,
    twelve: theme.image.casinoCarnivalPromotionBg,
    thirteen: theme.image.firstBetPromotionBg,
    // fifteen:theme.image.dailyDepBonusPromotionBg,
    twenty: theme.image.evoCasinoCupPromoBg,
    fifteen: theme.image.DailyDepoBonusPromoBanner,
    sixteen: theme.image.acbPromoBg,
    seventeen: theme.image.platinumRushBG,
    eighteen: theme.image.wcbetboostBg,
    nineteen: theme.image.catch99_2bg,
    twentytwo: theme.image.jetxPromoBanner,
    twentyone: theme.image.CrazyPachinkoTopBanner,
    // twentythree: theme.image.aviatrixPromoMainBanner,
    twentyfour: theme.image.cricketCMPromoMainBanner,
    twentyfive: theme.image.crazyTimeEBMainBanner,
    twentysix: theme.image.aviatorHighFlyerMainBanner,
    twentyseven: theme.image.smartSoftPromotionBanner,
    thirty: theme.image.powerplayBanner,
    _thirty: theme.image.aviatorSKMInnerBanner,
    thirtyone: theme.image.aviatrixFBEMainBanner,
    thirtytwo: theme.image.IndianGalaxsysLeagueMainBanner,
    twentyEight: theme.image.EvolutionCasinoCupPromotionBanner,
    twentyNine: theme.image.DoubleCashBackBanner,
    thirtyNine: theme.image.FomoWcLossBackExchBaner,
    thirtySeven: theme.image.FomoWcGoIndiaGoJackpotBanner,
    thirtyThree: theme.image.FomoAviatorWingsOfAdventureBanner,
    fortyOne: theme.image.FomoEvolutionCasinoChallengeBanner,
    fortyNine: theme.image.FomoAviatrixFlyHighBanner,
    fortySeven: theme.image.FomoWorldCupdailyDepositBanner,
    _thirtyNine: theme.image.WorldCupLossBackBannerYolo,
    _forty: theme.image.WorldCupDoublecashBackBonusBanner,
    _Sixtyforty: theme.image.WorldCupDoublecashBackBonusBanner,
    _fortyOne: theme.image.WorldCupEvolutioncasinoCupBanner,
    _Sixtyfive: theme.image.EvolutionCasinoTourneryBazziBanner,
    _SixtyNine: theme.image.DailyRewardsBannerBazzi,
    fourtytwo: theme.image.SmartSoftJetxCelestialBanner,
    fourtyNine: theme.image.MinesLegendTrophyBanner,
    fifty: theme.image.CRBInnerPromoBanner,
    fiftyOne: theme.image.EvolutionRouletteCupBanner,
    fiftyTwo: theme.image.AviatrixFlyAndPlayBanner,
    fiftyThree: theme.image.AviatorRainBanner,
    fiftyFour: theme.image.JetSetWithJetXBanner,
    fiftyFive: theme.image.WomensWorldCup2024Banner,
    fiftySix: theme.image.AviatrixJetTocashBanner,
    fiftySeveen: theme.image.BigBashBigCashBanner,
  }

  //add the state value,configKey and the text label here for getting the menu
  const betTypes = [
    { key: "first", show: theme.config.showWelcomeBonus, labelKey: "PROMOTIONS@WELCOMEBONS" },
    { key: "second", show: theme.config.showRefillBonus, labelKey: "PROMOTIONS@REFILLBONUS" },
    { key: "third", show: theme.config.showCashbackBonus, labelKey: "PROMOTIONS@CASHBACKBONUS" },
    { key: "four", show: theme.config.showReferralBonus, labelKey: "PROMOTIONS@REFERRAL" },
    { key: "fifth", show: theme.config.showAviatorBonus, labelKey: "PROMOTIONS@AviatorBonus" },
    // { key: "sixth", show: theme.config.showBBLBonus, labelKey: "PROMOTIONS@BBLBonus" },
    { key: "seventh", show: theme.config.showSingWithYolo, labelKey: "PROMOTIONS@singYolo" },
    { key: "eight", show: theme.config.jetxpromo, labelKey: "jetx" },
    { key: "nine", show: theme.config.showCrazyCrashPromo, labelKey: "PROMOTIONS@crazyCrashPromo" },
    { key: "tenth", show: theme.config.pslpromo, labelKey: "PROMOTIONS@psl" },
    // { key: "eleven", show: theme.config.showIplPromo, labelKey: "PROMOTIONS@iplmatka" },
    // { key: "twelve", show: theme.config.IPLCasinoCarnivalPromotion, labelKey: "PROMOTIONS@iplCasinoCarnival" },
    { key: "thirteen", show: theme.config.showEvolutionpromo, labelKey: "PROMOTIONS@firstperson" },
    // { key: "fourteen", show: theme.config.showFunkytimePromo, labelKey: "PROMOTIONS@funkytime" },
    { key: "twenty", show: theme.config.showEvoCasinoCupPromo, labelKey: "PROMOTIONS@EvoCasinoCupPromo" },
    { key: "fifteen", show: theme.config.showWCBonusPromo, labelKey: "PROMOTIONS@WCCBONAZA" },
    { key: "sixteen", show: theme.config.showAsiaCupBonanzaPromo, labelKey: "PROMOTIONS@ACBPromo" },
    { key: "seventeen", show: theme.config.showPlatinumRush, labelKey: "PROMOTIONS@Platinumrush" },
    { key: "eighteen", show: theme.config.showWCBonusPromo, labelKey: "TITLE@wcbetboost" },
    { key: "nineteen", show: theme.config.showCatch99Bonus, labelKey: "PROMOTIONS@CATCH99" },
    { key: "twentyone", show: theme.config.showCrazyPachinkoPromo, labelKey: "TITLE@EvoCrazyPachinko" },
    { key: "twentytwo", show: theme.config.showJetXCelestialRush, labelKey: "PROMOTIONS@JetxCelestial" },
    // { key: "twentythree", show: theme.config.showAviatrixPromo, labelKey: "PROMOTIONS@AviatrixDoubleDhamaka" },
    { key: "twentyfour", show: theme.config.showcircketCashbackMarathon, labelKey: "PROMOTIONS@CricketCashbackMarathon" },
    { key: "twentyfive", show: theme.config.showCrazyTimePromo, labelKey: "PROMOTIONS@CrazyTimeEpicBonanza" },
    { key: "twentysix", show: theme.config.showAviatorHighFlyerPromo, labelKey: "PROMOTIONS@AviatorHighFlyer" },
    { key: "twentyseven", show: theme.config.showSmartsoft, labelKey: "PROMOTIONS@Smartsoft" },
    { key: "twentyEight", show: theme.config.showEvolutionCasionCup, labelKey: "PROMOTIONS@EvolutionCasionCup" },
    { key: "thirty", show: theme.config.shoeIPLpowerPlay, labelKey: "PROMOTIONS@IPLPOWERPLAY" },
    { key: "thirtyone", show: theme.config.showAviatrixFBEPromo, labelKey: "PROMOTIONS@AviatrixFBE" },
    { key: "thirtytwo", show: theme.config.showIndianGalaxysLeague, labelKey: "PROMOTIONS@IndianGalaxsysLeague" },
    { key: "eleven", show: theme.config.showIplPromo, labelKey: "PROMOTIONS@iplmatka" },
    { key: "twentyNine", show: theme.config.showDoubleCashBack, labelKey: "PROMOTIONS@DoubleCashback" },
    { key: "thirtyNine", show: theme.config.showFomoWcLossBackExchPromo, labelKey: "PROMOTIONS@FomoWorldCupLossBackExch" },
    { key: "thirtySeven", show: theme.config.showFomoGoIndiaGoJackpotPromo, labelKey: "PROMOTIONS@FomoWorldCupGoIndiaGoJackpot" },
    { key: "thirtyThree", show: theme.config.showFomoAviatorWingsofAdventurePromo, labelKey: "PROMOTIONS@FomoAviatorWingsOfAdventure" },
    { key: "fortyOne", show: theme.config.showFomoEvolutionCasinoChallengePromo, labelKey: "PROMOTIONS@FomoEvolutionCasinoChallenge" },
    { key: "fortyNine", show: theme.config.showFomoAviatrixFlyHighPromo, labelKey: "PROMOTIONS@FomoAviatrixFlyHigh" },
    { key: "fortySeven", show: theme.config.showWcDailyDepositBonusPromo, labelKey: "PROMOTIONS@FomoWorldCupDailyDesposit" },
    { key: "_thirty", show: theme.config.showAviatorSkyBoundMiles, labelKey: "PROMOTIONS@AviatorSkyBoundMiles" }, 
    { key: "_thirtyNine", show: theme.config.showWorldCupLossbackYolo, labelKey: "PROMOTIONS@WorldCupLossbackBonus" }, 
    { key: "_forty", show: theme.config.showWorldCupDoubleCashbackBonus, labelKey: "PROMOTIONS@WorldCupDailyDepositBonus" }, 
    { key: "_fortyOne", show: theme.config.showWorldCupEvolutionCasinoCup, labelKey: "PROMOTIONS@WorldCupEvolutionCasinoCupBonus" }, 
    { key: "_Sixtyforty", show: theme.config.showWorldCupDoubleCashBackBazzi, labelKey: "PROMOTIONS@WorldCupDoubleCashbackBonusBazzi" },
    { key: "_Sixtyfive", show: theme.config.showEvolutionCasinoTourneyBazzi, labelKey: "PROMOTIONS@EvolutionCasinoTourneyBazzi" },
    { key: "_SixtyNine", show: theme.config.showDailyRewardsBazzi, labelKey: "PROMOTIONS@DailyRewardsBazzi" },
    { key: "fourtytwo", show: theme.config.showSmartSoftJetxCelestial, labelKey: "PROMOTIONS@SmartSoftJetxCelestial" },
    { key: "fourtyNine", show: theme.config.showMinesLegendTrophyPromo, labelKey: "PROMOTIONS@MinesLegendTrophy" },
    { key: "fifty", show: theme.config.showCaribbeanLeagueBonusPromo, labelKey: "PROMOTIONS@CaribbeanLeagueBonus" },
    { key: "fiftyOne", show: theme.config.showEvolutionRouletteCupPromo, labelKey: "PROMOTIONS@EvolutionRouletteCup" },
    { key: "fiftyTwo", show: theme.config.showAviatrixFlyAndPlayPromo, labelKey: "PROMOTIONS@AviatrixFlyAndPlay" },
    { key: "fiftySix", show: theme.config.showAviatrixJetToCrash, labelKey: "PROMOTIONS@AviatrixJetToCrash" },
    { key: "fiftyThree", show: theme.config.showAviatorRainPromo, labelKey: "PROMOTIONS@AviatorRainPromo" },
    { key: "fiftyFour", show: theme.config.showJetSetWithJetXPromo, labelKey: "PROMOTIONS@JetSetWithJetX" },
    { key: "fiftyFive", show: theme.config.showWomensWorldCupPromo, labelKey: "PROMOTIONS@WomensWorldCup" },
    { key: "fiftySeveen", show: theme.config.showBigBashBigCashPromo, labelKey: "PROMOTIONS@BigBashBigCash" },
  ]

  const tabClickHandler = (key) => {
      setactive(key);
      setactiveState(true);
      const elm = document?.querySelector('#tab_' + key);
      if (elm) {
          setTimeout(() =>
              elm.scrollIntoView({ behavior: "smooth", inline: "nearest",block: "end" })
            , 50)
      }
  }
  
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {
           currPage && structuredData(brandId,currPage)
          }

        </script>
      </Helmet>
    
    <MainInfoWrapper1 active={active}>
      <SEOContents pageName={seoCont} />
      <BannerWrapper>
        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${activeImageMap[active]}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
      </BannerWrapper>
      <BetTypeButton>
        <BetTypeButtonWrp>
          {/* {betTypes.map((elm) => (
            elm.show && <SegmentedWrapper 
                            onClick={() => setactive(elm.key)} 
                            className={active === elm.key ? "active" : null} 
                        >
                        <Intl langKey={elm.labelKey} />
                      </SegmentedWrapper>
          ))} */}

                {betTypes.map((elm, i) => {
                    return (
                        elm.show && <SegmentedWrapper
                            key={elm.key}
                            id={`tab_${elm.key}`}
                            onClick={() => tabClickHandler(elm.key)}
                            isActive={active === elm.key && activeState}
                        >
                            <Intl langKey={elm.labelKey} />
                        </SegmentedWrapper>
                    )
                })}
        </BetTypeButtonWrp>
      </BetTypeButton>

        {active === "first" && showWelcomeBonus ? (
          <>
            {showFomoWelcomeBonus ? <FomoWelcomeBonus /> : brandId==46|| brandId==47? <WelcomeBonusFun/>:(
              <InformationWrapper>
                <BonusInfoContainer>
                  <BonusInfoHeadH2><Intl langKey="PROMOTIONS@WELCOMEBONS" /></BonusInfoHeadH2>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSWELCOME@FIRSTLINE" />
                  </BonusInfoDesc>
                </BonusInfoContainer>
                <BonusInfoContainer>
                  <BonusInfoHead><Intl langKey="PROMOTIONSWELCOME@SECONDLINE" /></BonusInfoHead>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSWELCOME@SECONDLINEINFO" />
                  </BonusInfoDesc>
                  {/* {showTermsOnYolo && <BonusInfoHead><Intl langKey="PROMOTIONSWELCOME@FAQ" /></BonusInfoHead>}
                      {showTermsOnYolo && <BonusInfoHead><Intl langKey="PROMOTIONSWELCOME@HOWTOGET" /></BonusInfoHead>} */}
                  <BonusInfoDesc><Intl langKey="PROMOTIONSWELCOME@STEPS" /></BonusInfoDesc>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSWELCOME@EXAMPLE" /></BonusInfoDesc>
                  <ul>
                    <Intl langKey="PROMOTIONSWELCOME@LI" /><br></br>
                    <Intl langKey="PROMOTIONSWELCOME@LI2" /><br></br>
                    <Intl langKey="PROMOTIONSWELCOME@LI3" />
                  </ul>
                  <br />
                  <BonusInfoHead><Intl langKey="PROMOTIONSWELCOME@RO" /></BonusInfoHead>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSWELCOME@ROINFO" /> </BonusInfoDesc>
                  <BonusInfoDesc>
                    <PLWrap>
                      <PlLeft>
                        | PROFIT & LOSS |
                        <BorderSec></BorderSec>
                        TOTAL STAKE
                      </PlLeft>
                      <PlRight>
                        ≥ 50%
                      </PlRight>
                    </PLWrap>

                    <BonusInfoDesc>
                      <Intl langKey="PROMOTIONSWELCOME@stakesamt" />
                    </BonusInfoDesc><Intl langKey="PROMOTIONSWELCOME@ROINFO1" />
                    <br />
                    <Intl langKey="PROMOTIONSWELCOME@ROEXMPLE" /><BonusInfoDesc>

                      <Intl langKey="PROMOTIONSWELCOME@ROEXMPLE1" /> <br />
                      <Intl langKey="PROMOTIONSWELCOME@ROEXMPLE2" /> <br />
                      <Intl langKey="PROMOTIONSWELCOME@ROEXMPLE3" />
                    </BonusInfoDesc>
                  </BonusInfoDesc>
                </BonusInfoContainer>
                <BonusInfoContainer>
                  <BonusInfoHead><Intl langKey="PROMOTIONSWELCOME@TERMS" /></BonusInfoHead>
                  <BonusInfoDesc>
                    <ol>

                      <li><Intl langKey="PROMOTIONS@COMMON" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS1" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS2" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS3" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS14" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS4" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS5" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS6" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS7" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS8" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS9" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS10" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS11" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS12" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMS13" /></li>
                      <li><Intl langKey="PROMOTIONSWELCOME@TERMSp2p" /></li>

                    </ol>
                  </BonusInfoDesc>
                </BonusInfoContainer>
              </InformationWrapper>
            )}
          </>


      ) : active === "second" && showRefillBonus ? (
            <>
              {showFomoRefillBonus ? <FomoRefillBonus/> :  brandId==46|| brandId==47? <RefillBonusf88/>:(
                <InformationWrapper>
                  <BonusInfoHeadH1><Intl langKey="PROMOTIONS@RefillNewHead0" /></BonusInfoHeadH1>
                  <BonusInfoHeadH2><Intl langKey="PROMOTIONS@RefillNewHead1" /> </BonusInfoHeadH2>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSREFILL@RefillNewDesc1" /> </BonusInfoDesc>
                  <ul>
                    <li><Intl langKey="PROMOTIONS@RefillNewDesc101" /></li>
                    <li><Intl langKey="PROMOTIONS@RefillNewDesc102" /></li>
                    <li><Intl langKey="PROMOTIONS@RefillNewDesc103" /></li>
                  </ul>
                  <BonusInfoHeadH2><Intl langKey="PROMOTIONS@RefillNewHead2" /> </BonusInfoHeadH2>
                  <BonusInfoDesc><Intl langKey="PROMOTIONS@RefillNewDesc2" /> </BonusInfoDesc>
                  <BonusInfoDesc>
                    <ol>
                      <li><Intl langKey="PROMOTIONS@RefillNewDesc201" /></li>
                      <li><Intl langKey="PROMOTIONS@RefillNewDesc202" /></li>
                    </ol>
                  </BonusInfoDesc>
                  <BonusInfoHeadH2><Intl langKey="PROMOTIONS@RefillNewHead3" /> </BonusInfoHeadH2>
                  <ul>
                    <li><Intl langKey="PROMOTIONS@RefillNewDesc301" /></li>
                    <li><Intl langKey="PROMOTIONS@RefillNewDesc302" /></li>
                    <li><Intl langKey="PROMOTIONS@RefillNewDesc303" /></li>
                  </ul>
                  <BonusInfoDesc><Intl langKey="PROMOTIONS@RefillNewDesc304" /> </BonusInfoDesc>
                  <BonusInfoHeadH2><Intl langKey="PROMOTIONS@RefillNewHead4" /> </BonusInfoHeadH2>
                  <BonusInfoDesc><Intl langKey="PROMOTIONS@RefillNewDesc4" /> </BonusInfoDesc>
                  <BonusInfoDesc>
                    <ol>
                      <li><Intl langKey="PROMOTIONS@RefillNewDesc401" /></li>
                      <li><Intl langKey="PROMOTIONS@RefillNewDesc402" /></li>
                    </ol>
                  </BonusInfoDesc>
                  <BonusInfoHeadH2><Intl langKey="PROMOTIONS@RefillNewTermsHead" /> </BonusInfoHeadH2>
                  <BonusInfoDesc>
                    <ol>
                      <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc1" /></li>
                      <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc2" /></li>
                      <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc3" /></li>
                      <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc4" /> <br/>
                      <Intl langKey="PROMOTIONS@RefillNewTermsDesc5" /></li>
                      {/* <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc5" /></li> */}
                      <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc6" /></li>
                      <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc7" /></li>
                      <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc8" /></li>
                      <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc9" /></li>
                      <li><Intl langKey="PROMOTIONS@RefillNewTermsDesc10" /></li>
                    </ol>
                  </BonusInfoDesc>






                  {/* <BonusInfoHeadH2><Intl langKey="PROMOTIONSREFILL@REFILLBONUS" /> </BonusInfoHeadH2>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSREFILL@REFILLBONUS1" /> </BonusInfoDesc>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSREFILL@REFILLBONUS2" /></BonusInfoDesc>

                  <BonusInfoHead><Intl langKey="PROMOTIONSREFILL@REFILLBONUS3" /></BonusInfoHead>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSREFILL@REFILLBONUS4" /></BonusInfoDesc>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSREFILL@REFILLSTEPS" />
                    <br />
                    <Intl langKey="PROMOTIONSREFILL@REFILLSTEPS2" />
                  </BonusInfoDesc>
                  <BonusInfoContainer>
                    <BonusInfoHead><Intl langKey="PROMOTIONSWELCOME@TERMS" /></BonusInfoHead>
                    <BonusInfoDesc>
                      <ol>
                        <li><Intl langKey="PROMOTIONS@COMMON" /></li>
                        <li><Intl langKey="PROMOTIONSREFILL@TERMS1" /></li>

                        <li><Intl langKey="PROMOTIONSREFILL@TERMS2" /></li>
                        <li><Intl langKey="PROMOTIONSREFILL@TERMS3" /></li>
                        <li><Intl langKey="PROMOTIONSREFILL@TERMS4" /></li>
                        <li><Intl langKey="PROMOTIONSREFILL@TERMS5" /></li>
                        <li><Intl langKey="PROMOTIONSREFILL@TERMS6" /></li>
                        <li><Intl langKey="PROMOTIONSREFILL@TERMSp2p" /></li>
                      </ol>
                    </BonusInfoDesc>
                  </BonusInfoContainer> */}
                </InformationWrapper>
                // {/* {brandId === 33 || brandId === 34 ? <RefillBonusSEOBD /> : <RefillBonusSEO />} */}
              )}
            </>
          ) : active === "third" && showCashbackBonus ? (
            <>
              {showFomoLossBackBonus ? <FomoLossBackBonus /> : brandId==46|| brandId==47?<CashbackBonusf88/>:(
                <InformationWrapper>
                  <BonusInfoHeadH2><Intl langKey="PROMOTIONS@CASHBACKBONUS" /></BonusInfoHeadH2>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSCASHBACK@1STLINE" /></BonusInfoDesc>
                  <BonusInfoHead><Intl langKey="PROMOTIONSCASHBACK@2NDLINE" /></BonusInfoHead>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSCASHBACK@3RDLINE" /> </BonusInfoDesc>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSCASHBACK@4THLINE" /></BonusInfoDesc>
                  <BonusInfoDesc><Intl langKey="PROMOTIONSCASHBACK@5THLINE" /></BonusInfoDesc>
                  <BonusInfoContainer>
                    <BonusInfoHead><Intl langKey="PROMOTIONSWELCOME@TERMS" /></BonusInfoHead>
                    <BonusInfoDesc>
                      <ol>
                        <li><Intl langKey="PROMOTIONS@COMMON" /></li>

                        <li><Intl langKey="PROMOTIONSCASHBACK@TERM1" /></li>
                        <li><Intl langKey="PROMOTIONSCASHBACK@TERM2" /></li>
                        <li><Intl langKey="PROMOTIONSREFILL@aded1" /></li>
                        <li><Intl langKey="PROMOTIONSCASHBACK@TERM3" /></li>
                        <li><Intl langKey="PROMOTIONSCASHBACK@TERM4" /></li>
                        <li><Intl langKey="PROMOTIONSCASHBACK@TERM5" /></li>
                        <li><Intl langKey="PROMOTIONSCASHBACK@TERMS6" /></li>
                        <li><Intl langKey="PROMOTIONSCASHBACK@TERMSp2p" /></li>


                      </ol>
                    </BonusInfoDesc>
                  </BonusInfoContainer>
                </InformationWrapper>
              )}
            </>
          ) : active === "four" && showReferralBonus ? (
                <>
                  {showFomoReferralBonus ? <FomoWReferralBonus /> : brandId==46|| brandId==47?<ReferralBonusFunf88/> :(
                    <InformationWrapper>
                      <SEOContents pageName={"referralBonus"} />

                      <BonusInfoHeadH2><Intl langKey="PROMOTIONS@REFERRALHEAD" /></BonusInfoHeadH2>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@1st" /></BonusInfoDesc>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@2" /></BonusInfoDesc>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@3" /> </BonusInfoDesc>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@4" /></BonusInfoDesc>
                      {/* <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@5" /></BonusInfoDesc> */}
                      <BonusInfoHead><Intl langKey="PROMOTIONSREFERRAL@6" /></BonusInfoHead>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@7" /></BonusInfoDesc>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@8" /> </BonusInfoDesc>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@9" /></BonusInfoDesc>
                      <ul>
                        <li><Intl langKey="PROMOTIONSREFERRAL@91" /></li>
                        <li><Intl langKey="PROMOTIONSREFERRAL@92" /></li>
                        <li><Intl langKey="PROMOTIONSREFERRAL@93" /></li>
                      </ul>
                      {/* <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@10" /></BonusInfoDesc> */}
                      <BonusInfoHead><Intl langKey="PROMOTIONSExampleHead" /></BonusInfoHead>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@10" /></BonusInfoDesc>
                      <ul>
                        <li><Intl langKey="PROMOTIONSREFERRAL@101" /></li>
                        <li><Intl langKey="PROMOTIONSREFERRAL@102" /></li>
                      </ul>

                      <BonusInfoHead><Intl langKey="PROMOTIONS@Referafriend" /></BonusInfoHead>
                      <BonusInfoDesc><Intl langKey="PROMOTIONS@Referafriend01" /></BonusInfoDesc>
                      <BonusInfoDesc><Intl langKey="PROMOTIONS@Referafriend02" /></BonusInfoDesc>
                      <BonusInfoDesc><Intl langKey="PROMOTIONS@Referafriend03" /> </BonusInfoDesc>
                      <BonusInfoDesc><Intl langKey="PROMOTIONS@Referafriend04" /></BonusInfoDesc>
                      <BonusInfoDesc><Intl langKey="PROMOTIONS@Referafriend05" /></BonusInfoDesc>

                      <BonusInfoHead><Intl langKey="PROMOTIONSREFERRAL@RO" /></BonusInfoHead>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSWELCOME@ROINFO" /> </BonusInfoDesc>
                      <BonusInfoDesc>

                        <PLWrap>
                          <PlLeft>
                            | PROFIT & LOSS |
                            <BorderSec></BorderSec>
                            TOTAL STAKE
                          </PlLeft>
                          <PlRight>
                            ≥ 50%
                          </PlRight>
                        </PLWrap>
                      </BonusInfoDesc>
                      {!showTermsOnYolo && <BonusInfoDesc><Intl langKey="PROMOTIONSWELCOME@ROREQ" /></BonusInfoDesc>}

                      <ul>
                        <li><BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@RO1" /></BonusInfoDesc></li>
                        <li><BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@R02" /></BonusInfoDesc></li>
                        <li><BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@R03" /></BonusInfoDesc></li>
                        <li><BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@R04" /></BonusInfoDesc></li>
                      </ul>
                      <BonusInfoDesc><Intl langKey="PROMOTIONSREFERRAL@R0END" /></BonusInfoDesc>
                      <BonusInfoContainer>
                        <BonusInfoHead><Intl langKey="PROMOTIONSWELCOME@TERMS" /></BonusInfoHead>
                        <BonusInfoDesc>
                          <ol>
                            {/* <li><Intl langKey="PROMOTIONS@COMMON" /></li> */}
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM1" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM2" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM3" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM4" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM5" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM6" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM7" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM8" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM9" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM10" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM11" /></li>
                            <li><Intl langKey="PROMOTIONSREFERRAL@TERM12" /></li>
                          </ol>
                        </BonusInfoDesc>
                      </BonusInfoContainer>
                    </InformationWrapper>
                    // {/* {brandId == 33 ? <RefferalBonusSEOBD /> : brandId == 31 ? <RefferalBonusSEO /> : <></>} */}
                  )}
                </>
      ) : active === "fifth" && showAviatorBonus ? (
        <>

          <InformationWrapper className="aviatorBonus">
            <InfoRight>
              {/* <span><Intl langKey="PROMOTIONS@AviatorBonus"/></span> */}
              <span>Introducing Aviator's Skybound Miles-a thrilling opportunity for all aviator enthusiasts to take their gaming experience to new heights!</span>
              {/* <span><Intl langKey="PROMOTIONS@AviatorQoute1"/>,</span>
              <span><Intl langKey="PROMOTIONS@AviatorQoute2"/></span>
              <span><Intl langKey="PROMOTIONS@AviatorQoute3"/></span> */}
              {/* <span className="boldOne"><Intl langKey="PROMOTIONS@AviatorPrice"/></span> */}
              <KnowmoreBTN onClick={() => navigate('/aviator-bonus')}><Intl langKey="Aviator@KnowMore2" /> &gt;</KnowmoreBTN>
            </InfoRight>
          </InformationWrapper>
        </>
      ) : active === "sixth" && showBBLBonus ? (
        <>
          <BblBonusInner />
        </>
      ) : active === "seventh" ? showSingWithYolo && (

        <>

          <InformationWrapper className="singwithyolodown">
            <InfoLeft className="logo">
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.YoloSingLogo}`} alt="SingLogo" />
            </InfoLeft>
            <Paragraph>
              <p><Intl langKey="PROMOTIONS@singYolo1" /><KnowmoreBTN onClick={() => navigate('/arjith_promo')}> <Intl langKey="BUTTON@KnowMoreBtn" /> &gt;</KnowmoreBTN></p>

            </Paragraph>
            <InfoLeft className="logo">
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indexPic}`} alt="indexPic" />
            </InfoLeft>
          </InformationWrapper>
        </>
      ) : active === "eight" ? jetxpromo && (
        <>

          <InformationWrapper className="jetx">
            <InfoLeft className="jetlogo">
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxplane}`} alt="jetxplane" />
            </InfoLeft>
            <InfoLeft className="jetlogo">
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxlogo}`} alt="jetxlogo" />
            </InfoLeft>
            <Paragraph className="jpara">
              <p><Intl langKey="PROMOTIONS@jetx1" /><KnowmoreBTN onClick={() => navigate('/jetx_promo')} className="orange"> <Intl langKey="BUTTON@KnowMoreBtn" /> &gt;</KnowmoreBTN></p>
            </Paragraph>
          </InformationWrapper>
        </>
      ) : active === "nine" ? showCrazyCrashPromo && (
        <>

          <InformationWrapper className="crazyCrash">
            <InfoLeft>
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashChallengeLeftThumbs}`} alt="crazyCrashChallengeLeftThumbs" />
            </InfoLeft>

            <Paragraph className="jpara">
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashCallengeMainRIghtText}`} alt="crazyCrashCallengeMainRIghtText" />
              <KnowmoreBTN onClick={() => navigate('/CrazyCrash')} className="orange"> <Intl langKey="BUTTON@KnowMoreBtn" /> &gt;</KnowmoreBTN>
            </Paragraph>
          </InformationWrapper>
        </>
      ) : active === "tenth" ? pslpromo && (
        <>

          <InformationWrapper className="psl">
            {brandId == 33 || brandId == 34 ? <Paragraph className="psltext">
              <span>iss PSL Increase karo apne Chances to Win Unlimited Cash by taking part in our PSL 50 : 50 Contest held from 13th Feb-19th March 2023. </span> <KnowmoreBTN onClick={() => navigate('/psl-live-score')} className="pink"> <Intl langKey="BUTTON@KnowMoreBtn" /> &gt;</KnowmoreBTN>
            </Paragraph> : <Paragraph className="psltext">
              <span>Iss PSL banao Bahut sara PAISAA </span> by taking part in our <span className="two">PAISA SUPER LEAGUE </span>CONTEST held from 13th Feb-19th March 2023.<KnowmoreBTN onClick={() => navigate('/paisa-super-league')} className="pink"> <Intl langKey="BUTTON@KnowMoreBtn" /> &gt;</KnowmoreBTN>
            </Paragraph>}
          </InformationWrapper>
        </>
      ) : active === "eleven" ? showIplPromo && (
        <>
<IplBettingDhamaka/>
        </>
      ) : active === "twelve" ? IPLCasinoCarnivalPromotion && (
        <>

          {/* <InformationWrapper className="casinoCarnival">
            <Paragraph className="casinoCarnivaltext">
              <Intl langKey="casinoCarnivaltextline1" />
              <span><Intl langKey="casinoCarnivaltextline2" /></span>
              <Intl langKey="casinoCarnivaltextline3" /> <span>
                <Intl langKey="casinoCarnivaltextline4" />
              </span><Intl langKey="casinoCarnivaltextline5" />
              <KnowmoreBTN onClick={() => navigate('/ipl-casino-carnival')} className="yellow">
                <Intl langKey="BUTTON@KnowMoreBtn" /> &gt;
              </KnowmoreBTN>
            </Paragraph>
          </InformationWrapper> */}
        </>
      ) : active === "thirteen" ? (
        <>
          {/* <BannerWrapper></BannerWrapper> */}
          <InformationWrapper className="casinoCarnival">
            <img>

            </img>
            <Paragraph className="casinoCarnivaltext">
              Get ready for an electrifying experience with Evolution's mind-blowing BET & WIN promotion, where a jaw-dropping prize pool of <span style={{ color: '#FFDE2F' }}> INR 32 lakhs</span> awaits you! Brace yourself for an adrenaline-pumping adventure as you compete for a chance to win an incredible weekly pool prize of<span style={{ color: '#FFDE2F' }}> INR 800,000.</span>
              <KnowmoreBTN onClick={() => navigate('/evolution-first-bet')} className="gold">
                <Intl langKey="BUTTON@KnowMoreBtn" /> &gt;
              </KnowmoreBTN>
            </Paragraph>
          </InformationWrapper>
        </>) : active === "fourteen" ? showFunkytimePromo && (
          <>
            {/* <BannerWrapper></BannerWrapper> */}
            <InformationWrapper className="funkytime">
              <Paragraph className="funkytimetext">
                Step into the thrilling realm of Evolution's newly launched game, Funky Time, and unlock your path to victory! Don't miss out on this opportunity to win big!
                <KnowmoreBTN onClick={() => navigate('/funkytime')} className="pink">
                  KNOW MORE &gt;
                </KnowmoreBTN>
              </Paragraph>
            </InformationWrapper>
          </>) : active === "sixteen" ? showAsiaCupBonanzaPromo && (
            <>
              <InformationWrapper className="acbPromo">
                <InfoLeft className="acbInfoLeft">
                  <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.acbPromoLeftThumbs}`} alt="crazyCrashChallengeLeftThumbs" />
                </InfoLeft>
                <Paragraph className="acbPromoText">
                  <ListTypeText>
                    <span><label className="starIcon">★</label><Intl langKey="PROMOTIONS@acbPromoRightText1" /></span>
                    <span><label className="starIcon">★</label><Intl langKey="PROMOTIONS@acbPromoRightText2" /></span>
                    <span>
                      <label className="starIcon">★</label>
                      <Intl langKey="PROMOTIONS@acbPromoRightText3" />
                      <strong><Intl langKey="PROMOTIONS@acbPromoRightText4" /></strong>
                    </span>
                  </ListTypeText>
                  <KnowmoreBTN onClick={() => navigate('/asia-cup-bonanza')} className="yellowGradient">
                    KNOW MORE &gt;
                  </KnowmoreBTN>
                </Paragraph>
              </InformationWrapper>
            </>) : active === "twenty" ? showEvoCasinoCupPromo && (
              <>
                {/* <InformationWrapper className="evoCasinoCupPromo"> */}
                <EvoBaccaratBetWin />
                {/* </InformationWrapper> */}
              </>) : active === "fifteen" ? showWCBonusPromo && (
                <>
                  <WcCashbackBonanza />
                </>)

        : active === "sixteen" ? showAsiaCupBonanzaPromo && (
          <>
            <InformationWrapper className="acbPromo">
              <InfoLeft className="acbInfoLeft">
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.acbPromoLeftThumbs}`} alt="crazyCrashChallengeLeftThumbs" />
              </InfoLeft>
              <Paragraph className="acbPromoText">
                <ListTypeText>
                  <span><label className="starIcon">★</label><Intl langKey="PROMOTIONS@acbPromoRightText1" /></span>
                  <span><label className="starIcon">★</label><Intl langKey="PROMOTIONS@acbPromoRightText2" /></span>
                  <span>
                    <label className="starIcon">★</label>
                    <Intl langKey="PROMOTIONS@acbPromoRightText3" />
                    <strong><Intl langKey="PROMOTIONS@acbPromoRightText4" /></strong>
                  </span>
                </ListTypeText>
                <KnowmoreBTN onClick={() => navigate('/asia-cup-bonanza')} className="yellowGradient">
                  KNOW MORE &gt;
                </KnowmoreBTN>
              </Paragraph>
            </InformationWrapper>
          </>) :
          active === "seventeen" ? showPlatinumRush && (
            <>
              <PlatinumRush />
            </> ) :
            active === "eighteen" ? showWCBonusPromo && (
            <>
                <WCbetBoost />
            </>) : active === "nineteen" ? showCatch99Bonus && (
            <>
                <Catch99_s2 />
            </>) : active === "twentyone" ? showCrazyPachinkoPromo && (
            <>
                  <EvoCrazyPachinkoPromo />
            </>) : active === "twentytwo" ? showJetXCelestialRush && (
            <>
                  <JetXCelestialRush />
            </> ) : active === "twentythree" ? showAviatrixPromo && (
                <>
                  <AviatrixDoubleDhamaka />
                </>
            ) : active === "twentyfour" ? showcircketCashbackMarathon && (
              <>
                <CricketCashbackMarathon />
              </>
            ) : active === "twentyfive" ? showCrazyTimePromo && (
              <>
                <CrazyTimeEpicBonanza />
              </>
            ) : active === 'twentysix' ? showAviatorHighFlyerPromo && (
                 <>
                     <AviatorHighFlyer />
                 </>
            )  : active === 'twentyseven' ? showSmartsoft && (
              <>
                  <SmartsoftSpinPromo />
             

              </>
         ) : active === 'thirty' ? shoeIPLpowerPlay && (
          <>
           
              <IPLpowerPlayBonus />

          </>
     ) : active === '_thirty' ? showAviatorSkyBoundMiles && (
              <>
                  <AviatorSkyBoundMiles />
              </>
            ) : active === 'thirtyone' ? showAviatrixFBEPromo && (
              <>
                  <AviatrixFBEPromo />
              </>
            ) : active === 'thirtytwo' ? showIndianGalaxysLeague && (
              <>
                  <IndianGalaxsysLeague />
              </>
            )  : active === 'twentyEight' ? showEvolutionCasionCup && (
              <>
                <EvolutionCasinoCupPromotion />
              </>
            ) : active === 'twentyNine' ? showDoubleCashBack && (
              <>
                <DoubleCashBackPromo />
              </>
            ) : active === 'thirtyNine' ? showFomoWcLossBackExchPromo && (
              <>
                <FomoWorldCupLossBackExch />
              </>
            ) : active === 'thirtySeven' ? showFomoGoIndiaGoJackpotPromo && (
              <>
                <FomoWorldCupGoIndiaGoJackpot />
              </>
            ) : active === 'thirtyThree' ? showFomoAviatorWingsofAdventurePromo && (
              <>
                <FomoAviatorWingsofAdventure />
              </>
            ) : active === 'fortyOne' ? showFomoEvolutionCasinoChallengePromo && (
              <>
                <FomoEvolutionCasinoChallenge />
              </>
            ) : active === 'fortyNine' ? showFomoAviatrixFlyHighPromo && (
              <>
                <FomoAviatrixFlyHigh />
              </>
            ) : active === 'fortySeven' ? showWcDailyDepositBonusPromo && (
              <>
                <FomoWorldCupdailyDespositBonus />
              </>
            ) : active === '_thirtyNine' ? showWorldCupLossbackYolo && (
              <>
                <WorldCupLossBack />
              </>
            ) : active === '_forty' ? showWorldCupDoubleCashbackBonus && (
              <>
                <WorldCupDoubleCashBack />
              </>
            ) : active === '_fortyOne' ? showWorldCupEvolutionCasinoCup && (
              <>
                <WorldCupEvolutionCasinoCup />
              </>
            ) : active === '_Sixtyforty' ? showWorldCupDoubleCashBackBazzi && (
              <>
                <WorldCupDoubleCashBackBazzi />
              </>
            ) : active === '_Sixtyfive' ? showEvolutionCasinoTourneyBazzi && (
              <>
                <EvolutionCasinoTourneyBazzi />
              </>
            ) : active === '_SixtyNine' ? showDailyRewardsBazzi && (
              <>
                <DailyRewardsBazzi />
              </>
            ) : active === 'fourtytwo' ? showSmartSoftJetxCelestial && (
              <>
                <SmartSoftJetxCelestialPromo />
              </>
            ) : active === 'fourtyNine' ? showMinesLegendTrophyPromo && (
              <>
                <MinesLegendTrophy />
              </> 
            ) : active === 'fifty' ? showCaribbeanLeagueBonusPromo && (
              <>
                <CaribbeanRoyalBonus />
              </>
            ) : active === 'fiftyOne' ? showEvolutionRouletteCupPromo && (
              <>
                <EvolutionRouletteCup />
              </>
            ) : active === 'fiftyTwo' ? showAviatrixFlyAndPlayPromo && (
              <>
                <AviatrixFlyAndPlayPromo />
              </>
            ) : active === 'fiftySix' ? showAviatrixJetToCrash && (
              <>
                {(brandId == 31 || brandId == 32) ? <AviatrixJetToCashPromoYolo /> : 
                (brandId == 46 || brandId == 47) ? <AviatrixJetToCashPromoFun88 /> : <AviatrixJetToCashPromo  />}
              </>
            ) : active === 'fiftyThree' ? showAviatorRainPromo && (
              <>
                <AviatorRainPromo />
              </>
            ) : active === 'fiftyFour' ? showJetSetWithJetXPromo && (
              <>
                <JetSetWithJetXPromo />
              </>
            ) : active === 'fiftyFive' ? showWomensWorldCupPromo && (
              <>
                <WomensWorldCupPromo />
              </>
            ) : active === 'fiftySeveen' ? showBigBashBigCashPromo && (
              <>
                {(brandId == 39 || brandId == 40) ? <BigBashBigCashPromoFomo /> : 
                (brandId == 46 || brandId == 47) ? <BigBashBigCashPromoFun /> : <BigBashBigCashPromo />}
              </>
            ) : (
              <></>
            )
          }
    </MainInfoWrapper1>
    </>
  )
}
export default withTheme(withAppContext(BonusMain))


//Styled Components

const PLWrap = styled.div`
   display: flex;
   gap: 10px;
   align-items: center;
`
const PlLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const PlRight = styled.div`
    
`

const MainInfoWrapper1 = styled(MainInfoWrapper) <Pick<StyleProps, 'active'>>`
  background-image: ${(props) =>
    props.active === 'fifteen' || props.active === 'eighteen' ||
      props.active === 'seventeen' || props.active === 'eighteen' || props.active === 'twenty' || props.active === 'sixth' || props.active === 'twentyone' || props.active === 'twentytwo'
      ? 'none'
      : 'url(https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/homeExchangeBg.png)'};
`;

export const ListTypeText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  strong {
    display: contents;
    color: #FFC51B;
  }

  .starIcon {
    position: absolute;
    left: -20px;
    display: flex;
    color: #FFC51B;
  }

  span {
    position: relative;
    color: #fff;
  }

  .textCenter {
    text-align: center;
    text-transform: capitalize;
  }
`;

const Paragraph = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
& p{width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: 18px;
  color:white;
  flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }


  & img {
    width: 80%;
  }

`
const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    transition: 700ms;
    height: 55vh;

    img {
        max-height: 100%;    
        object-fit: cover;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }
`;

export const KnowmoreBTN = styled.button`
  display: flex;
  right: 0px;
  cursor: pointer;
  width: auto;
  align-items: flex-end;
  padding: 9px 13px;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: ${theme('palette.secondary-background-color-linear-new1')};
  border-radius: 5px;
  border: none;
  right: 25px;
  margin-top: 10px;
`;

const InfoLeft = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 75%;
    height: auto;
  }

  &.acbInfoLeft {
    img {
      width: 100%;
    }
  }
`


const InfoMiddle = styled.div`
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 100%;
      height: auto;
      max-height: 100%;
  }
`

const InfoRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 20px;
  width: 10%;
  text-align: right;
  line-height: 23px;

  @media only screen and (max-width: 1317px){
    width: 11%;
  }

  .boldOne {
    font-weight: 900;
    font-size: 18px;
    color: ${theme('palette.InfoRightBoldOneColor')};
  }
`;

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem;
    border:1px solid white;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    backdrop-filter: brightness(0.5);

    & ul li{
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 25px;
      text-align: justify;
      -webkit-letter-spacing: 0.01em;
      -moz-letter-spacing: 0.01em;
      -ms-letter-spacing: 0.01em;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: #FFFFFF;
      margin: 5px 0px;
    }

    /* &.catch99 {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      gap: 5px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.catch99downBg')});
      height: 55vh;
      background-position: center;
      background-repeat:no-repeat;
      max-width: 100%;
      background-size: cover;
      position: relative;    
    } */

    &.evoCrazyPachinko{
      padding: 0rem;
    }

    &.casinoCarnival {
        max-width: 100%;
        height: 55vh;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.firstBetPromotiondown')});
        background-position: center;
        background-repeat:no-repeat;
        background-size: cover;
    }
    &.funkytime {
        max-width: 100%;
        height: 55vh;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.funkytimePromotiondown')});
        background-position: center;
        background-repeat:no-repeat;
        background-size: cover;
    }

    &.dailyDepoBonus {
      max-width: 100%;
      height: 55vh;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.dailyDepoBonusPromotiondown')});
      background-position: center;
      background-repeat:no-repeat;
      background-size: cover;
    }

    &.acbPromo {
      max-width: 100%;
      height: 55vh;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.acbPromotiondown')});
      background-position: center;
      background-repeat:no-repeat;
      background-size: cover;
    }

    &.evoCasinoCupPromo {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.evoCasinoCupPromotionDown')});
      background-position: center;
      background-repeat:no-repeat;
      background-size: cover;
    }

    &.fifaFever {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      gap: 5px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.fifafeverpromotionsNew')});
      height: 55vh;
      background-position: center;
      background-repeat:no-repeat;
      max-width: 100%;
      background-size: cover;
      position: relative;    

      & span {
        font-weight: 300;
        margin: 5px 0px;
       }
    }

    &.bblBonus {
      display: flex;
      /* align-items: flex-end;
      flex-direction: column; */
      text-align: left;
      justify-content: space-between;
      gap: 5px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.BBLMainBGGreen')});
      height: auto;
      background-position: center;
      background-repeat:no-repeat;
      max-width: 100%;
      background-size: cover;
      position: relative;
      padding: 0rem;    

      & span {
        font-weight: 300;
        margin: 5px 0px;
       }

       & .bblBonusInfoRight{
        position: static;
        width: 18%;
        justify-content: center;
        padding: 0rem 1rem;
       }
    }

    .jpara{
      width: 33%;
    }

    .iplMB{
      display: inline-block;
      justify-content: space-around;
      align-items:flex-start;
      padding-left:6rem;
      padding-right: 2rem;
      font-size: 18px;
      line-height:30px;
      text-transform: capitalize;
      color: #FFFFFF;
      span{
        display: inline-block;
        color: #DABA57;
      }
    }

    .iplClass{
      width: 80%;
      img{
        width: 100%;
      }
    }

    .psltext{
      display: flex;
      width: 29%;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      text-transform: uppercase;
      color: #FFFFFF;
      padding: 2.5rem;
      background: rgba(69, 43, 102, 0.5);
      border: 1px solid #FAE013;
      backdrop-filter: blur(2px);
      border-radius: 21px;
      align-items: flex-start;
      span{
        text-transform: uppercase;
        color:#F3DF3B;
      }
    }

    .casinoCarnivaltext {
      width: 38%;
      font-size: 17px;
      line-height: 23px;
      display: inline-block;
      text-transform: capitalize;
      color: #FFFFFF;
      padding: 2.5rem;
      margin-right: 20rem;
      span{
        display: inline-block;
        padding: 0 5px;
        font-size: 18px;
        text-transform: capitalize;
        color:#FFD084;
      }
    }

    .funkytimetext{
      width: 450px;
      font-size: 17px;
      line-height: 23px;
      display: inline-block;
      text-transform: capitalize;
      color: #FFFFFF;
      padding: 2.5rem;
      margin-right: 20rem;
      span{
        display: inline-block;
        padding: 0 5px;
        font-size: 18px;
        text-transform: capitalize;
        color:#FFD084;
      }
    }

    .dailyDepoBonustext{
      width: 650px;
      font-size: 17px;
      line-height: 23px;
      display: inline-block;
      text-transform: capitalize;
      color: #FFFFFF;
      padding: 2.5rem;
      margin-right: 4rem;
      span{
        display: inline-block;
        padding: 0 5px;
        font-size: 18px;
        text-transform: capitalize;
        color:#FFD084;
      }
    }

    .acbPromoText {
      width: 55%;
      font-size: 17px;
      line-height: 23px;
      display: inline-block;
      text-transform: capitalize;
      color: #FFFFFF;
      padding: 2.5rem;

      .yellowGradient {
        background: linear-gradient(225deg, #F28500 0%, #FFEC2D 49.48%, #FF9E00 100%);
        filter: drop-shadow(0px 0px 15px rgba(255, 255, 68, 0.50));
        color: #000;
        font-weight: bold;
      }
    }

    .jetlogo{
      width: 20%;
      img {
        width: 80%;
        height: auto;
      }
  }
  .orange{
    background: #FEFD00;
    color:black;
  }
  .pink{
    background: #FF0E99;
    color: #000;
  }
  .gold{
    background: linear-gradient(183.17deg, #CEA63D 0%, #FDF3A0 50.52%, #CFA840 100%), #EF4123;
    color: #000;
  }
  .pink{
    background: linear-gradient(183.17deg, #B21764 0%, #DE117C 50.52%, #C60067 100%), #EF4123;
    box-shadow: 0px 0px 32.8716px rgba(255, 0, 133, 0.6);
    color: white;
  }

  .red {
    background: linear-gradient(81.28deg, #540000 -46.36%, #FC0000 79.56%, #760119 202.88%);
    color: white;
  }

  .darkGold {
    background: linear-gradient(180deg, #c28637 20.21%, #EECB67 38.90%, #b9720d 56.87%);
    color: #000;
  }

    &.singwithyolodown {
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.singwithyolodown')});
        height: 55vh;
        background-position: center;
        background-repeat:no-repeat;
        max-width: 100%;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.jetx {
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.jetxbg')});
        height: 55vh;
        background-position: center;
        background-repeat:no-repeat;
        max-width: 100%;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.psl{
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.psldown')});
        height: 55vh;
        background-position: center;
        background-repeat:no-repeat;
        max-width: 100%;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
          }
          .two{
           color: #2BF733;
         }
    }

    &.iplmatka{
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.ipldownBG')});
        height: 55vh;
        background-position: center;
        background-repeat:no-repeat;
        max-width: 100%;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.crazyCrash {
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashInnerBg')});
        height: 55vh;
        background-position: center;
        background-repeat:no-repeat;
        max-width: 100%;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & ${InfoLeft} {
          width: 50%;

          & img {
            width: 100%;
          }
        }

        .jpara{
          width: 45%;
          display: flex;
          align-items: flex-start;

          & p {
             width: 80%;
             font-size: 15px;
             text-transform: capitalize;

           & > img {
            width: 80%;
           }
          }
        }
    }


    .logo{
      width: 50%;
      img {
        width: 100%;
        height: auto;
      }
 
    }
    .aviatorInfo{
      align-items: center;
      text-align: center;
      color: white;
      margin-right: 4rem;
    }


    &.aviatorBonus {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      gap: 5px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatordownBgEng')});
      height: 55vh;
      background-position: center;
      background-repeat:no-repeat;
      max-width: 100%;
      background-size: cover;
      position: relative;

      @media only screen and (max-width: 1317px){
        background-position: 60%;
      }
    }

    & span{
      display: flex;
      flex-direction: column; 
      right: 0px;
      width: auto;
      /* font-family: 'Roboto'; */
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      text-transform: capitalize;
      color: #FFFFFF;
      align-items: flex-end;

      @media only screen and (min-width: 1700px){
        font-size: 16px;
      }
    }
`


export const SegmentedWrapper: any = styled.div`
${ifProp(
  "isActive",
  css`
        /* background: linear-gradient(0deg,#6778E3,#6778E3),linear-gradient(0deg,#2C286A,#2C286A),linear-gradient(0deg,#6778E3,#6778E3),linear-gradient(92.92deg,#6F6DE6 0%,#5451D4 45.8%,#312DB9 93.54%); */
        background: ${theme('palette.promotionTabBackGroundColor')};
        color: white;
        
        `,
  css`
        background: ${theme('palette.promotionTabBg')};
        color: #000;
        `
)}
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-left: 0px;
        cursor: pointer;
        user-select: none;
        font-weight:bold;
        font-size: 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        flex-grow: 0;
        white-space: nowrap;
        padding: 0 10px;
        text-transform: uppercase;
        flex: 1;
        height: 100%;
        width: 350px;
        /* white-space: break-spaces; */
        text-align: center;

        @media only screen and (min-width: 1700px){
          font-size: 17px;
        }
`;



const BetTypeButton: any = styled.div`
    width: 98%;
    display: flex;
    overflow: hidden;
    margin-bottom: 1em;
    background: ${theme('palette.promotionTabBetTypeBg')};
    border: none;
    /* flex:4; */
    margin: 0em 1.2em;
    margin-top: -4px;
    /* max-height:45px;
    height:45px; */
  
  :after{
    width: 135px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
    padding: 1em 0px;
    cursor: pointer;
    border: 0.25px solid;
    border-radius: 3px;
    margin-right: 10px;
    background: ${theme('palette.secondary-background-color-linear-new1')};
    color: #fff;
  }

  .active {
    background: ${theme('palette.secondary-background-color-linear-new1')};
              color: white;
        }
`;

const BetTypeButtonWrp = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
overflow-y: hidden;
border: none;
overflow-x: auto;
height:54px;

&::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #161313;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background:#474646;
    margin-bottom: 0px;
  }

`

export const BorderSec = styled.div`
    width: 100%;
    border-top: 1px solid #D2D8EA;
`
const BonusInfoContainer = styled.div`
   width: 100%;
   margin: auto;

   & h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #C2D2FF;
    margin: 25px 0px 20px 0px;
   }

   & h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 5px 0px;
   }
   & ul,li {
    font-style: normal;
    /* list-style-type: none; */
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    text-align: justify;
    padding-left: 0px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;
   }
`
const BonusInfoHeadH2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #E3B73E;
  margin: 15px 0px;
`

const BonusInfoHeadH1 = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #E3B73E;
  margin: 15px 0px;
`

const BonusInfoHead = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #E3B73E;
  margin: 15px 0px;
`
export const BonusInfoDesc = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #FFFFFF;
  margin: 5px 0px;
  ol{
list-style-type: decimal;
  }
`
