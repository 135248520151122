import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import { Link } from 'react-router-dom';

type Props = { app: any }

const SattaMatkaFun88 = (props: Props) => {

    const { app: { brandId } } = props;
    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7' : (brandId === 46 || brandId === 47) ? 'FUN88' : 'Betdaily';
    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>Satta Matka by Betgames</h1></label>
                    <div className="accordion__content">
                        <h2>Satta Matka: Play the Exciting Casino Version at FUN88</h2>
                        <p>Experience the excitement of Satta Matka at FUN88's Live Casino, where we proudly offer the first-ever Casino version of this popular game. Play Satta Matka online with real hosts at our exclusive table, designed specifically for FUN88 customers.</p>
                        <p>Also known as "Matka," this game has a rich history and has undergone various transformations over the years. We are delighted to introduce the groundbreaking card-version of Satta Matka, exclusively available at FUN88.</p>
                        <p>To learn everything, you need to know about playing Satta Matka online, continue reading the sections below. Discover more about this thrilling game and get ready to enjoy the ultimate gaming experience at FUN88.</p>
                        <h2>How to Play Satta Matka</h2>
                        <p>Experience the thrill and intuitive nature of Satta Matka, making it a highly sought-after game. In this section, we will walk you through the step-by-step process of playing the card version, guaranteeing that you'll become a pro in no time.</p>
                        <ul>
                            <li>To kick off the game, the dealer will draw three cards, such as 3, 6, and 9.</li>
                            <li>These card numbers are then arranged in ascending order, with zero (10, if drawn) placed at the end. For example, the cards 3, 6, and 9 become 369.</li>
                            <li>This combination is known as a Pana (e.g., 369 in this instance). The Single Number or Ank is derived from the sum of the digits in the Pana. In simpler terms, add up the three numbers. If the sum results in a two-digit number (greater than 9), consider only the last digit as the Single Number or Ank. For instance, in this case, 3+6+9=18. Discard the first digit, resulting in '8' as the Single Number.</li>
                        </ul>
                        <p><strong>Your goal is to accurately predict the Pana and its corresponding Single Number (Ank) that will be drawn by the dealer.</strong></p>
                        <p>During the main game, a single deck of 40 cards is utilized, with Jacks, Queens, and Kings removed.</p>
                        <p>Satta Matka offers a wide range of betting options, each offering varying payouts based on the probability of the drawn cards. Depending on your wager amount, you can earn payouts ranging from 1.9 times to an impressive 700 times your initial bet.</p>
                        <p>With betting starting as low as ₹10 and reaching up to ₹10000, the potential for substantial winnings awaits you.</p>
                        <p>Seize the opportunity to immerse yourself in the world of Satta Matka, where excitement and potential winnings converge.</p>
                        <h2>How does the Card-version work?</h2>
                        <p>Learn how the card-version of Satta Matka works at FUN88, where traditional numbers are substituted with card values. In this variant, aces hold a value of 1, while 2 to 9 retain their respective numerical values, and 10 is represented as 0.</p>
                        <p>As mentioned earlier, Pana digits are always arranged in ascending order, resulting in a total of 220 unique Pana combinations in the game.</p>
                        <ul>
                            <li>Panas are categorized into Single Pana (SP), Double Pana (DP), and Triple Pana (TP).</li>
                            <li>Single Pana (SP) consists of three distinct numbers, with 120 SP combinations out of the total 220 Panas. For example, 369.</li>
                            <li>Double Pana (DP) comprises two identical digits and one different digit, offering 90 DP combinations out of the total 220 Panas. For example, 336.</li>
                            <li>Triple Pana (TP) features all three numbers being the same, with 10 TP combinations out of the total 220 Panas. For example, 333.</li>
                        </ul>
                        <p>Players have the flexibility to bet on various Pana types. They can choose to bet on any SP, DP, or TP category, where they win if the drawn card numbers fall within the selected category. Additionally, players can also wager on self-selected Pana numbers, winning if the exact card numbers match.</p>
                        <h3>Regarding the "Single number" or "Ank," players can place bets on:</h3>
                        <ul>
                            <li>Chosen Number (Select one from 0-9) - 9.5 times the bet amount</li>
                            <li>Low Line Single Number (1, 2, 3, 4, 5) - 1.9 times the bet amount</li>
                            <li>High Line Single Number (6, 7, 8, 9, 0) - 1.9 times the bet amount</li>
                            <li>Odd Single Number (1, 3, 5, 7, 9) - 1.9 times the bet amount</li>
                            <li>Even Single Number (2, 4, 6, 8, 0) - 1.9 times the bet amount</li>
                        </ul>
                        <p>The aforementioned betting options offer the following payouts:</p>
                        <h3>Pana (self-selected three-digit number):</h3>
                        <ul>
                            <li>SP (Selected 3 digits forming an SP) - 140 times the bet amount</li>
                            <li>DP (Selected 3 digits forming a DP) - 240 times the bet amount</li>
                            <li>TP (Selected 3 digits forming a TP) - 700 times the bet amount</li>
                        </ul>
                        <h3>Single Pana (SP):</h3>
                        <ul>
                            <li>SP of Selected Single Number (Ank) (Choose one from 0-9) - 12 times the bet amount</li>
                            <li>Any SP - 1.22 times the bet amount</li>
                        </ul>
                        <h3>Double Pana (DP):</h3>
                        <ul>
                            <li>DP of Selected Single Number (Ank) (Choose one from 0-9) - 40 times the bet amount</li>
                            <li>Any DP - 4 times the bet amount</li>
                        </ul>
                        <h3>Triple Pana (TP):</h3>
                        <ul>
                            <li>TP of Selected Single Number (Ank) (Choose one from 0-9) - 700 times the bet amount</li>
                            <li>Any TP - 200 times the bet amount</li>
                        </ul>
                        <p>Explore the captivating world of the Card-Version in Satta Matka, where strategic bets can lead to remarkable payouts.</p>
                        <h2>How to Place a Bet</h2>
                        <p>Ready to place your bets and dive into the exciting world of Satta Matka? Follow these simple steps to get started:</p>
                        <ul>
                            <li>Select the category or specific number you wish to bet on. Simply click on your preferred choice.</li>
                            <li>Next, enter your desired bet amount in the designated "Amount" box. Instantly discover the potential winnings associated with your bet.</li>
                            <li>Once you have made your selection, tap the "Place bet" button to confirm your wager. A message &ldquo;Bet accepted&rdquo; will appear after your bet is placed.</li>
                            <li>The dealer will then deal the cards, and if you win, your winnings will be credited to your account instantly.</li>
                            <li>As the next round begins, your betting options will be reset, allowing you to make new choices.</li>
                        </ul>
                        <p>With a wide range of categories and chosen numbers to bet on, including options for Single and Pana, the betting possibilities are vast, and the odds are favorable. Feel free to place bets on multiple options, maximizing your chances of winning.</p>
                        <p>Additionally, you have the option to place bets on specific Bazaar options and check the results later. This feature is perfect for those seeking an authentic, time-based draw-style experience of the game.</p>
                        <p>Embrace the thrill of Satta Matka by placing strategic bets and exploring the diverse betting opportunities available.</p>
                        <h2>FUN88&rsquo;s Satta Matka Online Experience</h2>
                        <p>FUN88 introduces an exclusive live gaming experience with our captivating Satta Matka online game. Step into the world of this classic game infused with the excitement of a casino.</p>
                        <p>Unleash the potential for massive wins as you indulge in our Satta Matka online game. Enjoy the live action with real dealers, delivering an authentic casino atmosphere directly to your home or wherever you choose to play.</p>
                        <p>With our 24/7 available tables, the thrill of Satta Matka awaits you at any time that suits your schedule.</p>
                        <p>Immerse yourself in a world of endless possibilities with our casino version. Bet on multiple options, adding an extra layer of excitement to your gameplay. Join countless fellow enthusiasts as the game accommodates an unlimited number of players, fostering a vibrant and engaging community.</p>
                        <p>Prepare for a dynamic and fast-paced Satta Matka live experience. The next set of numbers is just moments away from being dealt, offering you an exhilarating chance to claim your victory.</p>
                        <p>Unleash the thrill of FUN88's Satta Matka online game and brace yourself for an unforgettable gaming journey. The excitement and opportunity to win big await you at every turn.</p>
                        <h2>Satta Matka Strategy</h2>
                        <p>Unlock the captivating world of Satta Matka, where intrigue and intuition converge. Embrace the unpredictable nature of the game, where every card drawn brings suspense and excitement. However, to maximize your chances of success, it's crucial to grasp the fundamentals and comprehend the diverse payout odds for various options.</p>
                        <p>With each round of Satta Matka played using a single deck of 40 cards meticulously arranged in ascending order, it's essential to note that the entire deck offers only 220 Pana possibilities. These are further categorized into 120 Single Panas (SP), 90 Double Panas (DP), and 10 Triple Panas (TP).</p>
                        <p>Expanding your betting horizons can also prove fruitful in your pursuit of victory. Discover more about our Other Bets in the informative section below, unveiling additional pathways to enhance your winnings.</p>
                        <h2>Satta Matka Other Bets</h2>
                        <p>Looking to expand your horizons beyond numerical predictions? Indulge in the excitement of Other Bets, offering a delightful array of options to elevate your Satta Matka experience.</p>
                        <p>Dare to venture beyond numbers and discover the thrill of placing bets on drawn card suits. Unleash the potential for extra wins and infuse your gameplay with an added dose of excitement.</p>
                        <p>Here's a glimpse of the enticing possibilities within Other Bets:</p>
                        <ul>
                            <li><strong>More Red or More Black:</strong> Bet on whether the drawn cards will have a greater number of red or black suits, with an enticing payout of 1.9 times your initial bet amount.</li>
                            <li><strong>All Red or All Black:</strong> Embrace the allure of extreme outcomes by betting on all cards being of the same suit&mdash;either all red or all black&mdash;offering an enticing payout of 8.2 times your original bet amount.</li>
                            <li><strong>More Spades, Diamonds, Hearts, or Clubs:</strong> Immerse yourself in the realm of specific suits and wager on whether the drawn cards will comprise a larger number of spades, diamonds, hearts, or clubs. With odds set at 6.35 times your initial bet amount, the potential for thrilling wins awaits.</li>
                        </ul>
                        <p>Engaging in these color and suit-based bets is an effortless and straightforward way to add variety to your Satta Matka gameplay. Explore the range of available odds and embrace the excitement of wagering on colors and suits in this captivating game.</p>
                        <h2>What is a Satta Matka Bazaar?</h2>
                        <p>Curious about the intriguing concept of the Bazaar in Satta Matka? Prepare yourself for an exhilarating experience rooted in the time-based draw-style of the original game.</p>
                        <p>In the Bazaar, two sets of Pana are drawn&mdash;one at the beginning and another at the end of the game. This unique twist adds an extra layer of excitement to your gameplay.</p>
                        <p>Within the Bazaar, you'll find several enticing betting options, including:</p>
                        <ul>
                            <li><strong>Open Single and Close Single:</strong> Bet on the single numbers drawn in the first and second draws, respectively. Take your chances and predict these crucial digits to elevate your winning potential.</li>
                            <li><strong>Jodi:</strong> Put your intuition to the test and guess both the opening and closing single numbers. This exciting betting option offers an opportunity for substantial wins.</li>
                            <li><strong>Half Sangam A and B:</strong> Delve into the simplicity of Half Sangam bets. Half Sangam A involves betting on the Pana numbers of the opening draw, while Half Sangam B focuses on the Pana numbers of the closing draw. Feel the rush as you decide to bet on either option or both.</li>
                            <li><strong>Full Sangam or "Sangam":</strong> Take your Satta Matka experience to the next level by attempting to accurately guess both the opening and closing Panas. This challenging bet promises significant rewards for those who make the right predictions.</li>
                        </ul>
                        <p>Each Bazaar category, such as Maanik Morning, Starline Sunset, and Navratna Night, offers unique betting opportunities. Engage in the Open, Close, Jodi, and Sangam options tailored to each specific category.</p>
                        <p>Please note that the Bazaar categories became available from November 2021 onward, ensuring an immersive and up-to-date gaming experience.</p>
                        <p>Prepare to immerse yourself in the captivating world of the Satta Matka Bazaar and seize the chance to unlock thrilling victories.</p>
                        <h2>Common Satta Matka Terms</h2>
                        <p>If you're new to our exclusive online game, it's important to familiarize yourself with some common terms used in Satta Matka.</p>
                        <p><strong>Draw</strong> &ndash; Consists of one deal of cards which begins with the betting round and ends when the presenter announces result of a draw (drawing out of cards).</p>
                        <p><strong>Betting outcome</strong> &ndash; The interpretation or changing nature of the outcome is expressed in the odds, which may change during the draw depending on the change of the mathematical probability of the outcome.</p>
                        <p><strong>Betting round</strong> &ndash; The time allocated for players&rsquo; bets to be accepted.</p>
                        <p><strong>Betting options</strong> &ndash; A list of all betting outcomes available to the player.</p>
                        <p><strong>Deck</strong> - A set of 40 (forty) cards; consisting of ten cards from each of the four suits (Two, Three, Four, Five, Six, Seven, Eight, Nine, Ten). Jacks, Queens and Kings are removed completely.</p>
                        <p><strong>Suit</strong> &ndash; Consists of Hearts, Diamonds, Spades and Clubs.</p>
                        <p><strong>Value</strong> &ndash; Place of the card in the series of the same suit (e.g., Ace, Deuce ... Ten), where the Ace has the lowest (1) and the Ten (10) has the highest (0) value. In Satta Matka 0 is a higher value than 9.</p>
                        <p><strong>Pana</strong> - A three-digit number set ranging from 000 to 999. Of all 1000 three-digit numbers there are only 220 Pana&rsquo;s since Pana digits are always arranged in an increasing format (ascending order). Zero is always placed at the last place in the number. For eg; in case of 7, 0, 3 &ndash; the Pana will be 370.</p>
                        <p><strong>Single</strong> - Each Pana is permanently attributed to its Single number (ank) based on the sum of its three digits. If the sum of three digits of a Pana is more than 9, then last digit of the sum is considered as the Single (ank) (e.g., 4 5 9 (4+5+9) =18, in this case 4 5 9 belongs to Single (ank) 8 (taken from the last digit of the sum of 18).</p>
                        <p><strong>Single Pana (SP)</strong> &ndash; A Pana in which all 3 digits are unique (different). There are 120 Single Panas out of the 220 total Panas (e.g., 159 is a Single Pana).</p>
                        <p><strong>Double Pana (DP)</strong> - A Pana in which two digits are the same (e.g., 113 is a Double Pana).</p>
                        <p><strong>Triple Pana (TP)</strong> &ndash; A Pana in which all three digits are the same (e.g., 444 is a Triple Pana).</p>
                        <p><strong>Cut card</strong> &ndash; special card used to cover the bottom card to prevent it from being exposed.</p>
                        <p><strong>Combination</strong>&ndash; 3 dealt cards create a combination. See paragraph 4. for possible combinations.</p>
                        <p><strong>Matka</strong> - Refers to a traditional earthenware pot used to draw number slips during the game.</p>
                        <p><strong>Satta</strong> - Translates to "Betting," representing the essence of the game.</p>
                        <p><strong>Other bets</strong> - Exciting betting options that extend beyond the main game.</p>
                        <p><strong>Bazaar</strong> - Translates to "Market" and denotes the specific betting options available at different times of the day within distinct categories.</p>
                        <p><strong>Open Single</strong> - The Single/Ank derived from the opening Pana in the Bazaar.</p>
                        <p><strong>Close Single</strong> - The Single/Ank derived from the closing Pana in the Bazaar.</p>
                        <p><strong>Jodi</strong> - Meaning "Pair," it represents the combination of both the opening and closing Singles.</p>
                        <p><strong>Half-Sangam A</strong> - The three-digit Pana numbers from the opening draw in the Bazaar.</p>
                        <p><strong>Half-Sangam B</strong> - The three-digit Pana numbers from the closing draw in the Bazaar.</p>
                        <p><strong>Sangam</strong> - The combination of both the opening and closing Pana numbers in the Bazaar, resulting in a total of six digits (3 x 2).</p>
                        <p>By understanding these fundamental Satta Matka terms, you'll enhance your gaming experience and fully immerse yourself in the world of exciting possibilities.</p>
                        <h2>History of Satta Matka in India</h2>
                        <p>The captivating history of Satta Matka in India has witnessed remarkable transformations over the years, shaping its enduring legacy in the country's cultural fabric.</p>
                        <p>Back in 1961, the New York Cotton Exchange imposed a ban on Satta Matka in India, leading enthusiasts to explore alternative variations of the game. During this period, Ratan Khatri, a Pakistani migrant, introduced a groundbreaking concept. Bets were now placed on the opening and closing prices of fictional products and playing cards. Players would carefully note these numbers on card-like paper sheets, which were then placed in a jug, giving rise to the game's name, "Matka." Similar to a lottery, players would draw a card and reveal the corresponding numbers. Although the rules evolved over time, with three winning ranks drawn from the deck, the game retained its original title.</p>
                        <p>The 80s and 90s marked a significant boom in the popularity of Satta Matka. However, law enforcement agencies conducted extensive raids to crack down on illegal gambling associated with the game. At its peak, certain cities boasted over two thousand sales offices in 1995. Since then, the number of such establishments has significantly reduced to around 300, primarily concentrated in the Maharashtra region, where Satta Matka continues to thrive.</p>
                        <p>In addition to the traditional Indian Madhur Matka, modern online platforms offer an extensive array of popular card games and number-based games for instant play. Games like Teen Patti and Andar Bahar have gained immense popularity among players seeking immersive and entertaining online experiences.</p>
                        <p>Furthermore, Satta Matka's cultural significance extends beyond gameplay. It has made its mark on various forms of artistic expression, including movies and literature. The 1959 Hindi film "Satta Bazaar," featuring renowned actors Meena Kumari and Balraj Sahni, delves into the game's themes and dynamics, captivating audiences with its compelling narrative. More recently, the 2007 film "Guru," starring Abhishek Bachhan and Aishwarya Rai Bachhan, sheds light on the captivating world of the Bombay cotton trade exchange and the origins of Satta Matka. The film highlights the intriguing process of accurately predicting the opening and closing rates of cotton trade between the United States and India.</p>
                        <p>Through these cinematic works, Satta Matka's cultural influence shines brightly, uncovering its historical roots and enthralling storytelling, resonating with audiences nationwide.</p>
                        <p>This profound influence extends beyond the silver screen, permeating the realm of literature and other art forms, solidifying Satta Matka's position as an integral part of India's rich cultural heritage.</p>
                        <h2>Satta Matka Tips</h2>
                        <p>Before embarking on your thrilling Satta Matka journey, equip yourself with these valuable tips that can enhance your experience:</p>
                        <p><strong>Master the game inside-out:</strong> While Satta Matka is a game of chance, it has its own set of rules. Familiarize yourself with the game mechanics and understand its logic. By grasping the basics, you'll be better prepared to navigate the complexities of the game.</p>
                        <p><strong>Play with bonus first:</strong> Many online casinos, including FUN88, offer bonuses on first deposit. Take advantage of this opportunity to practice and learn the ropes without risking much money. This allows you to develop strategies that could potentially improve your chances of winning.</p>
                        <p><strong>Harness the power of bonuses:</strong> FUN88 provides enticing bonuses for both new and regular players. As a new member, seize the lucrative welcome bonus to give your winning odds a significant boost. Additionally, take advantage of various deals such as cashbacks and reloads available to regular players.</p>
                        <p><strong>Start with small wagers:</strong> It's easy to get carried away by early success, but remember that luck can change swiftly. To safeguard your bankroll, start by placing small bets, even if you're on a winning streak. This precaution ensures that you don't risk substantial amounts of money prematurely.</p>
                        <p><strong>Calculate your winning chances:</strong> Each Satta Matka bet offers different payout rates, ranging from 1.9/1 to 700/1. Before placing a wager, calculate the potential winnings or losses associated with your chosen bet. Always exercise caution and avoid betting more than you can comfortably afford to lose.</p>
                        <p><strong>Know when to stop:</strong> Gambling can be addictive, so it's crucial to establish boundaries. Set a daily budget and allocate a specific time limit for your gambling activities. This discipline ensures that you stay in control and prevents excessive involvement.</p>
                        <p><strong>Responsible Gaming:</strong> To maintain a healthy balance, it's crucial to take regular breaks and practice responsible spending habits. At FUN88, we offer Session Reminders, allowing you to monitor your gameplay duration effectively. Additionally, you have the option to set deposit limits on a daily, weekly, or monthly basis, empowering you to control your expenditure.</p>
                        <p>By following these Satta Matka tips, you can approach the game with confidence, make informed decisions, and enhance your overall enjoyment while maintaining responsible gambling practices.</p>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(SattaMatkaFun88));