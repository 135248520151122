import React from 'react'
import { Link } from 'react-router-dom';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';

function SeoContentHomePage47(props) {
    const { app: {
        brandId
    }, theme: { config } } = props;

    let domainURL = 'https://www.gofun88.in';

    if (brandId === 46) {
        domainURL = 'https://www.fun88.site';
    }
    else if (brandId === 47) {
        domainURL = 'https://www.gofun88.in';
    }

    const domainName = (brandId === 46 || brandId === 47) ? 'Fun88' : 'Fun88';

    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>FUN88: Play on the best online betting site in India</h1></label>
                    <div className="accordion__content">

                        <p>It's time to put your gaming skills to the test and earn big rewards daily! Bet on your favourite online casino games and make sports predictions and win big on Fun88! Experience a live casino atmosphere and best of sports betting options from the comfort of your home.</p>

                        <p>Fun88 is one of India's top online betting sites, available on your desktop or mobile phone. The online gaming platform allows <strong>you to bet online</strong> with extensive choices in casino games, sports betting markets, odds, and online bonuses. <strong>Fun88</strong> is backed with superior technology and easy, <strong>safe</strong>, and secure transactions, offering complete value for money!</p>

                        <p>Fun88 is among the few online betting sites accepting Indian Rupees (INR). From pre-match to in-play bets on local and international events, Fun88 offers the best online betting options on cricket, tennis, football, kabaddi betting, and exciting live sports action. With Fun88's easy on-site navigation and interactive bet types, <Link to="/betting-exchange">online sports betting</Link> has never been easier.</p>

                        <p>At <strong>Fun88</strong>, our team of experts helps you stay informed with the latest sports news, <strong>online betting odds</strong> and tips to facilitate your online sports betting predictions. Follow the <Link to="/blog/">Fun88 Blog</Link> to be on top of the <strong>online betting</strong> news, tips and tricks. We offer exclusive access to information, behind the scenes action, and many giveaways.</p>

                        <h2>Plenty of Online Casino Games & Sports Betting on FUN88 to Win!</h2>

                        <p>If you are looking beyond sports betting, Fun88's line-up of online casino games will dazzle and delight you. Choose from Slots, Poker, Roulette, Craps, Cards, Crash, Board games, and more. And be it single spins to skill-based games, simple card games to dazzling video online slots, you can enjoy a superior gaming and <strong>online betting experience in India</strong> in FUN8  online casino.</p>

                        <p>We've also brought in your online casino favourites. Fun88's <Link to="/live-casino">live casino</Link> offers popular card games like <Link to="/live-casino/teenpatti">Teen Patti</Link>,  <Link to="/live-casino/andar-bahar">Andar Bahar</Link>, etc., along with exciting betting options on casino games like Roulette, Blackjack, and Baccarat. Our well-known and cutting-edge gaming partners - Microgaming, Playtech, Superspade, Ezugi, Evolution Gaming, etc. - make <strong>betting online</strong> easy and reliable. These gaming providers power <strong>Fun88's</strong> online casinos.</p>

                        <h4>Online Sports Betting</h4>
                        <p>Fun88 offers thousands of live events to bet on and win real money through Sportsbook, Saba Sports, <Link to="/premium-sportsbook">B2B</Link>, and Betfair Sports <Link to="/betting-exchange">Exchange betting</Link>. Fun88 India's Sportsbook online betting covers international cricketing worldwide, including T20, ODI, Test cricket formats. </p>
                        <p>Fun88 Sportsbook is packed with features that make it the best online betting site in India. Football fans will also enjoy betting on their favorite clubs like Manchester United, Real Madrid, FC Barcelona, Newcastle United FC, and Tottenham Hotspur FC.</p>

                        <h4>Virtual Sports Betting</h4>
                        <p>Enjoy online <Link to="/virtualSports">virtual sports betting</Link> at Fun88! Bet any time of the year, even when there are no football, basketball, or cricket matches. When it comes to betting online on virtual sports, there's no better bookmaker than Fun88! You can enjoy <strong>betting online</strong> on horse racing, car racing, ice hockey, etc.!</p>

                        <h4>Online Casino Games</h4>
                        <p><Link to="/live-casino">Play live casino games</Link> at Fun88 India and win instant rewards. You'll find the FUN88 online smooth and easy to navigate, making casino gambling a world-class experience. Moreover, Fun88 slots games are simple to discover. Play Poker, Baccarat, and Rummy games online, offered by the best casino software providers like <Link to="/live-casino/ezugi">Ezugi</Link>, Playtech, <Link to="/live-casino/evolution">Evolution Gaming</Link>, etc.</p>

                        <h4>Online Slots Games</h4>
                        <p>Get the Vegas experience at your fingertips. Fun88 offers thousands of <Link to="/slots">slot games</Link> with different themes and high-quality graphics. Play Slot games on Fun88 offered by popular providers like <Link to="/slots/netent">Netent</Link>, Microgaming, Playtech, and Pragmatic Play. You will also find 3D slots, high limit, low limit games, etc.</p>

                        <h4>Online Card Games</h4>
                        <p>Do you like playing popular Indian card games like Indian Poker, Andar Bahar, and Teen Patti? If so, you can choose from <Link to="/live-casino/super-spade">Superspade</Link>, <Link to="/live-casino/ezugi">Ezugi</Link>, and <Link to="/live-casino/ag">Amazing Gaming</Link> providers on the Fun88 <Link to="/live-casino">Live Casino</Link> page. Fun88 has emerged as the best Poker site in India because of our constant technology updates, automated shuffle switches, and game integrity. </p>

                        <h2>What to Expect when Signing up on Fun88 Online?</h2>
                        <p>Get ready to win exciting bonuses and promotions daily! <strong>Fun88 makes</strong> depositing money and withdrawing your winnings easy and safe. Available on Android and iOS devices, Fun88 is one of the best <strong>online betting sites</strong> in India and many other parts of Asia. <strong>Fun88</strong> permits instant fund withdrawal and allows you to bet in Indian currency <strong>(INR)</strong>.</p>
                        <p>Fun88 also allows you to enjoy <strong>betting online</strong> on sports, and <strong>playing</strong> online casino games in cryptocurrency. You can create a deposit using Bitcoin at our site. Also, you can earn a Bitcoin bonus, and an additional welcome bonus.</p>
                        <p><Link to="/signup">Join Fun88 India</Link>, login, and try your luck today. Lagao Shart on Fun88 Aur Kamao!</p>

                        <h2>FUN88's Best Betting Online Features</h2>
                        <p><strong>Best Online Betting Odds –</strong> If you want the best online betting odds, join Fun88. Bet on any sport or your favourite casino game; with Fun88, you can rest assured that you'll get the most competitive betting odds. There are no hidden costs, and Fun88 keeps its margins as low as possible by giving you a significantly higher chance of winning on your bets. In a nutshell, Fun88 offers you the complete online betting experience. So, research your markets well and start betting online at Fun88.</p>

                        <p><strong>Betting Exchange –</strong> We also offer a betting exchange that allows punters to bet against each other. Why do you need a betting exchange? You may not find the betting option online on which you want to place your bet on our platform. In that case, the FUN88 betting exchange allows you to offer bets to other punters. Besides, the commissions on bets placed through the exchange are much lower than on Sportsbook.</p>

                        <p><strong>Easy Deposit & Withdrawal of Funds –</strong> You'll never find any glitches at Fun88 while depositing and withdrawing funds. Our payment system is hassle-free and foolproof, ensuring your money is safe. We use popular and convenient payment modes, like Netbanking, UPI, Google Pay, VISA, MasterCard, Payz, Cryptocurrency, Astropay, and Sticpay to ensure seamless fund transfer. You can deposit money on Fun88's site in a wink, and we instantly credit winnings to your account. Fun88 is undoubtedly one of India's best online betting websites for those who want to bet in Indian currency (INR).</p>

                        <p><strong>All Cricket Tournaments Covered –</strong> Fun88 understands that Indian punters love making cricket predictions on all matches of team India, including betting on the World Cup. Be it India vs Australia, India vs England, India vs New Zealand, or  ICC tournaments like the ICC T20 World Cup, ODI World Cup, or the popular Indian Premier League (IPL), Fun88 covers all these events and provides exciting betting online options for bettors to place their bets on.</p>

                        <p><strong>Betting on Global Cricket Leagues –</strong> There are many cricket leagues worldwide where hardly any Indian player participates, like the Big Bash League, Pakistan Super League (PSL), and the Caribbean Premier League (CPL). At Fun88 India, you'll find online betting options for all these leagues. Millions of Indian cricket lovers are keen to bet on these popular overseas leagues. And why not? Talented players like Babar Azam, Faf du Plessis, Andre Russell, Shaheen Shah Afridi, etc., play in such leagues. We are committed to offering the best online betting in India. That's why we are also the official sponsor of the Caribbean Premier League (CPL).</p>

                        <h2>How to Get Started with Online Betting in India?</h2>
                        <p>Are you ready to try your luck with betting online on Fun88? Let us tell you how to choose the best online betting sites in India.</p>

                        <ol>
                            <li>Register on FUN88 with a username, password, and a valid mobile number.</li>
                            <li>After registering, make an online betting deposit through UPI, Google Pay, NetBanking (Online Bank Transfer), Debit/Credit Card, or even Cryptocurrency!</li>
                            <li>After making your first deposit, claim your online betting welcome bonus!</li>
                            <li>Check Fun88's competitive online betting odds.</li>
                            <li>You are now ready to place bets online.</li>
                        </ol>
                        <p><strong>Watch How to Place a Bet While Betting Online on Fun88</strong></p>

                        <h2>Online Betting on Other Sports</h2>
                        <p>Indians love Cricket, but other sports like Tennis, Football, Volleyball, Basketball, and Kabaddi also have an ardent following in India. Fun88 is your ideal <Link to ="/betting-exchange">sports betting destination online</Link> for these sports.</p>
                        <p>We offer fantastic odds and betting markets for football fans. Football leagues from around the world are available to bet on FUN88. Through our exclusive partnerships and sponsorships, we offer match tickets, t-shirts, and other giveaways from your favourite clubs like Newcastle United FC, Tottenham Hotspur FC, etc. Apart from football leagues, you can bet on the UEFA Euro Cup, FIFA World Cup, and several other international events.</p>
                        <p>For tennis fans, we accept bets on Grand Slams and ATP events. On FUN88, several online Tennis betting markets provide you with the thrill of betting on tennis every season.</p>

                        <h2>Betting online for popular sports betting</h2>
                        <p>Sports betting online in India has huge demand. With online sports betting being legal in India, punters can join FUN88 online sports betting website and app to check the live sports events, compare the best online betting odds, and place a bet on them. Some major sports betting markets include:</p>

                        <h3>Cricket</h3>
                        <p>Cricket is undeniably one of the most popular sports for online betting markets in India, attracting avid bettors keen on <Link to="/betting-exchange/online-cricket-betting">betting on cricket</Link>. Cricket bettors can choose to bet on T20 internationals, Test matches, ODIs, the Indian Premier League (IPL), Asia Cup, ODI & T20 World Cups, Caribbean Premier League (CPL), Women’s IPL (WPL), and other cricket tournaments going on all through the year.</p>

                        <h3>Football</h3>
                        <p>We've lined up a thrilling <Link to="/betting-exchange/football-betting">Football betting online</Link> experience for football lovers. Football fans will also be in for a treat as we've lined up favourite clubs - Newcastle United, Tottenham Hotspur and others - with tickets, t-shirt giveaways and competitions! As the official shirt sponsor and betting partner, we've enhanced the engagement levels of football fans in many ways.</p>

                        <h3>Kabaddi</h3>
                        <p>Kabaddi is now considered India's major sporting event. The Vivo Pro Kabaddi League has generated massive attraction in the betting market. Many famous Kabaddi players have gained an immense fan following in the betting markets in India.</p>

                        <h3>Basketball</h3>
                        <p>Bettors also enjoy betting online on basketball tournaments as well. At Fun88, you can bet on NBA, domestic leagues worldwide, international tournaments, etc. Bet on Basketball leagues on Fun88 and get the winning strategies of playing online basketball betting.</p>

                        <h2>The benefits of an online betting site in India</h2>
                        <p>Here are just a few of the benefits that players in India can expect to find when betting online at Fun88.</p>

                        <ul>
                            <li><em>A wide range of sports types, casino games, slots, and markets to bet on</em></li>
                            <li><em>24/7 access through the mobile betting</em></li>
                            <li><em>Unique bonuses to boost your bankroll </em></li>
                            <li><em>Convenient deposit and withdrawal methods</em></li>
                            <li><em>Early cash-out features</em></li>
                            <li><em>Faster sign-up process</em></li>
                        </ul>

                        <h2>Online betting odds explained</h2>
                        <p>Online betting odds usually come in one of three formats: decimals, fractions and American (Moneyline) odds. Head to Fun88’s sports betting odds page for a detailed understanding.</p>

                        <h3>Decimal odds</h3>
                        <p>A format is a number wherein the odds are represented as 2.0. Decimals are mostly used in Asia, continental Europe, Australia and Canada.</p>

                        <h3>Fractional odds</h3>
                        <p>A format with a fractional number. odds of 1/1 are known as ɾven money’, which is the same as 2.0 in decimals. You will see fractional odds on UK online betting sites.</p>

                        <h3>Moneyline odds</h3>
                        <p>Moneyline odds or American odds are listed as a number with a plus or minus symbol. The favourite team will have negative (-) odds, while the underdog will have positive (+) odds.</p>

                        <h3>Malaysian odds</h3>
                        <p>Malaysian odds or Malay odds are expressed in decimals, either positive or negative. If the odds are 0 then it is an exact 50/50 bet and will pay out 1/1.</p>

                        <h3>Hong Kong odds</h3>
                        <p>Also known as HK odds, Hong Kong odds are one of the simplest odds formats. Plus (+) and minus (-) symbols are used to denote the relationship between your stake and your profit.</p>

                        <h3>Indonesian odds</h3>
                        <p>Indonesian odds are also known as Indo odds. They are the same odds just divided by 100. The plus (+) and minus (-) signs are used to represent the favorite and underdog team or player.</p>

                        <h2>Popular Betting Types & Markets in Online Betting in India</h2>
                        <p>Before you begin betting online, familiarize yourself with the bet types and markets you might come across. We’ve listed some of the most popular betting types and markets for online betting in India.</p>

                        <h3>Single bets</h3>
                        <p>A single bet is placed on just one selection. Generally, single bets are considered the safest betting option.</p>

                        <h3>Accumulator bets</h3>
                        <p>Accumulators are popular in the world of online betting. Accumulator bets combine three or more selections into a single wager, with each odd getting multiplied by the next.</p>

                        <h3>In-play bets</h3>
                        <p>Live or in-play betting happens during an ongoing event. This is a real-time wager that is popular in online cricket and football betting.</p>

                        <h3>System bets</h3>
                        <p>With a system bet, your selection could have a mix of wins and losses, because the bet placed commonly has a higher winning percentage for bettors to cover more outcomes.</p>

                        <h2>Online Betting Markets in India</h2>
                        <p>Fun88 offers exciting betting online markets, so take advantage of our expert suggested online betting odds as mentioned below.</p>

                        <h3>Match odds</h3>
                        <p>Match odds are about gaining a return based on the outcome, with no additional factors or bets required.</p>

                        <h3>Correct score</h3>
                        <p>Correct score betting is where you put your wager on the final score of a match. Fun88 offers exciting odds for correct score wagers.</p>

                        <h3>Correct Totals</h3>
                        <p>The correct totals market is a wager on whether the total of points scored in a game is over or under the bookmaker’s prediction.</p>

                        <h3>Specials</h3>
                        <p>Specials betting is gaining popularity wherein the bookmaker creates one-of-a-kind bets that might not exist on other online betting sites.</p>

                        <h2>Interesting facts about online betting in India</h2>

                        <ul>
                            <li><em>Online slots machines generate the most profit online.</em></li>
                            <li><em>Cricket is one of the most popular sports to bet on.</em></li>
                            <li><em>80% of sports bets in India are placed on IPL matches.</em></li>
                            <li><em>Football is followed by 41% of people in India </em></li>
                        </ul>

                        <h2>Factors to consider while choosing the best betting sites in India</h2>
                        <p>There are several factors to take into consideration before you choose an online betting site in India. Here are a few of them:</p>

                        <ul>
                            <li>Wide range of betting markets</li>
                            <li>Competitive online betting odds</li>
                            <li>Exciting promotions & bonuses</li>
                            <li>In-play or live betting</li>
                            <li>User-friendly app</li>
                            <li>VIP programs</li>
                            <li>Numerous payment methods</li>
                            <li>Instant credit of winnings</li>
                            <li>Secure & valid gambling license</li>
                            <li>24x7 customer service</li>
                        </ul>

                        <h2>Best bonuses and promotions for online betting in India</h2>
                        <p>Fun88 offers exciting <Link to="/promotions">bonuses and promotional offers</Link> for new and seasoned bettors. New bettors can register on Fun88’s platform and get a first-time bonus on sports bets. At the same time, the already registered players can also get a bonus on their deposit amount, giving you a weekly cashback bonus on your losses incurred on sports bets.</p>
                        <p>Hold your breath for what we will tell you next. At Fun88, you can place bets on T20 cricket matches and win fabulous prizes like a BMW–X7, Suzuki Hayabusa bike, iPhone 13 Pro, etc. Bet on IPL and ICC T20 events on Fun88 to win these exciting prizes.</p>

                        <h2>FAQs</h2>

                        <h3>How to do online betting on Fun88 India?</h3>
                        <p>Betting online at Fun88 is very easy. Just register on our website or download our app. You will find multiple online casino games and sports betting events to bet on. Log in to FUN88, make a deposit, choose a casino game or a sporting event and place your bet.</p>

                        <h3>Is betting online in Indian Rupees possible at Fun88?</h3>
                        <p>Fun88 allows you to bet in Indian Rupees. So, Indian bettors don't have to convert their local currency to place their bets. Just visit the Fun88 portal and start betting online in Indian Rupees.</p>

                        <h3>Are online betting sites in India safe?</h3>
                        <p>Fun88 is the safest online betting site in India. It is advisable to only use an accredited online betting site that is licensed and independently regulated.</p>

                        <h3>What are the other ways of online betting in India?</h3>
                        <p>You can also bet at online casinos on Fun88 to play games like Slots, Blackjack and Roulette.</p>

                        <h3>What are the payment methods for online betting in India on FUN88?</h3>
                        <p>Generally, the best online betting sites in India offer the following modes of payment:</p>

                        <ul>
                            <li>UPI apps</li>
                            <li>PhonePe</li>
                            <li>Paytm</li>
                            <li>Google Pay (GPay)</li>
                            <li>Cryptocurrency</li>
                            <li>Skrill</li>
                            <li>Neteller</li>
                            <li>Payz</li>
                            <li>Debit cards/credits cards (Visa & Mastercard)</li>
                        </ul>

                        <h3>How to claim the Fun88 betting bonus on Fun88?</h3>
                        <ol>
                            <li>Create a new Fun88 account by typing in your details.</li>
                            <li>Deposit Fun88 using any of our payment methods.</li>
                            <li>After successful deposit, the welcome bonus will be activated.</li>
                        </ol>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(SeoContentHomePage47))

//Styled Components
export const Accordion = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: ${theme('palette.seoContenAccordionHeadTextColor')};
    background: ${theme('palette.seoContenAccordionHeadBg')};

    .accordion__label, .accordion__content {
        padding: 14px 20px;
    }

    h1 {
        display: block;
        font-size: 1em;
        font-weight: bold;
        margin: 0;
    }

    h2 {
        display: block;
        font-size: 1.5em;
        font-weight: bold;
    }

    h3 {
        display: block;
        font-size: 1.1em;
        font-weight: bold;
    }

    .number {
        list-style: number;

        li {
            font-weight: bold;
        }
    }

    ul {
        li {
            font-size: 0.91em;
        }
    }

    p {
        margin: 5px 0 10px 0;
        color: ${theme('palette.seoContenAccordionInnerTextColor')};
        font-size: 0.91em;
        font-weight: normal;
    }

    a {
        /* color: #f4a322; #0366FD */
        color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
    }

    .linkBg {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: wrap;
        gap: 5px;

        a {
            background: #1e1e1e;
            border-radius: 5px;
            padding: 5px 10px;
        }
    }

    .textTransformNone {
        text-transform: none;
    }

    .accordion__label{
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme('palette.seoContenAccordionHeadTextColor')};
        padding: 20px;
        background: ${theme('palette.seoContenAccordionHeadBg')};
        text-align: center;
        position: relative;
        cursor: pointer;

        :after {
            content: "";
            display: inline-block;
            border-style: solid;
            border-color: ${theme('palette.seoContenAccordionArrowBorder')};
            border-width: 0 2px 2px 0;
            padding: 2.5px;
            vertical-align: middle;
            transition: transform 0.3s;
            transform: ${theme('palette.seoContenAccordionArrowMarkAfter')};
            position: absolute;
            right: 20px;
        }
    }

    .accordion__label:hover{
        background: ${theme('palette.seoContenAccordionHeadHoverBg')};
    }

    .accordion__content {
        background: ${theme('palette.seoContenAccordionContentBg')};
        line-height: 1.6;
        font-size: 0.85em;
        opacity: ${theme('palette.seoContenAccordionContentOpacityOpen')};
        text-transform: capitalize;
        max-height: ${theme('palette.seoContenAccordionContentMaxHeightOpen')};
        padding: ${theme('palette.seoContenAccordionContentPaddingOpen')};
        margin: ${theme('palette.seoContenAccordionContentMarginOpen')};
        overflow-x: ${theme('palette.seoContenAccordionContentOverFlowXOpen')};
        overflow-y: ${theme('palette.seoContenAccordionContentOverFlowYOpen')};
        transition: 0.3s;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
          width: 10px;
          background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background:gray;
          margin-bottom: 10px;
        }
    }

    .accordion__input {
        display: none;
    }

    .accordion__input:checked ~ .accordion__content {
        opacity: ${theme('palette.seoContenAccordionContentOpacityClosed')};
        max-height: ${theme('palette.seoContenAccordionContentMaxHeightClosed')};
        padding: ${theme('palette.seoContenAccordionContentPaddingClosed')};
        margin: ${theme('palette.seoContenAccordionContentMarginClosed')};
    }

    .accordion__input:checked ~ .accordion__label:after {
        transform: ${theme('palette.seoContenAccordionArrowMarkBefore')};
    }

    .td,th{
        border: 1px solid white;
    }
  
    .table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}

`;

export const AccordionWrapper = styled.div`
  table,th,td{
    border: 1px solid white;
  }
`;