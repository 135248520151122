import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import AGseo46 from './AGseo46';
import AgSEOYolo from './AgSEOYolo';

type Props = {
    app:any
}

const AgSEO = (props: Props) => {
    const { app :{brandId}}=props;
    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;
    return (
        <>
        {
            (brandId === 33 || brandId === 46) ? <></> :
            (brandId === 31 || brandId === 32) ? <AgSEOYolo /> :
            (brandId === 47) ? <AGseo46 /> :
            <AgSEOYolo />
        }
        </>
    )
}

export default withTheme(withAppContext(AgSEO));