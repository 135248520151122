import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import SpribeSEOf88 from './SpribeSEOf88';
import SpribeSEOYolo from './spribeSEOYolo';

type Props = {app:any}

const SPRIBESEO = (props: Props) => {
    const { app: { brandId } } = props;
    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;
    return (
        <>
            {
                (brandId === 46 ) ? <></> :
                (brandId == 47) ? <SpribeSEOf88 /> :
                <SpribeSEOYolo />
            }
        </>
    )
}

export default withTheme(withAppContext(SPRIBESEO)) ;