import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import { Link } from 'react-router-dom';

type Props = { app: any }

const Skywardf88 = (props: Props) => {

    const { app: { brandId } } = props;
    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7' : (brandId === 46 || brandId === 47) ? 'FUN88' : 'Betdaily';
    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>Skyward by BetGames
                    </h1></label>
                    <div className="accordion__content">
                        <h2>Play Skyward Crash Game by BetGames</h2>
                        <p>Welcome to the exhilarating world of Skyward, the thrilling game brought to you by Bet Games, exclusively on FUN88! Get ready to embark on a high-flying adventure where the sky's the limit and the rewards are sky-high. Skyward is an <Link to={'/instant-games'}>instant games</Link> where players can place bets before the take-off of a flying Object, aiming to cash out at the right moment to maximize their winnings.</p>
                        <p>Players place bets before the take-off of the Flying Object. The multiplier starts at 1.00 and progressively increases as the flight progresses. The objective is to cash out before the Flying Object flies away to secure winnings.</p>
                        <p>In eligible jurisdictions, players have the option to configure an Autoplay feature, enabling automated gameplay based on predefined variables.</p>
                        <h2>How To Place a Bet on Skyward Game?</h2>
                        <ul>
                            <li><Link to={'/login'}>Login</Link> to your account.</li>
                            <li>Make a deposit to your account if needed</li>
                            <li>Select an amount and press the "Place bet" button.</li>
                            <li>You can make up to two bets per draw: choose predefined or add custom amount in bet panel(s) and press the "Place bet" button.</li>
                            <li>During ongoing draw press the “Cash out” button to cash out winnings.</li>
                            <li>Your bet is lost, if you didn‘t press button “Cash out” before the plane flies away.</li>
                        </ul>
                        <h2>Key Terminology in Skyward Games</h2>
                        <ul>
                            <li><strong>Flying Object:</strong> The object taking off, subject to players' bets.</li>
                            <li><strong>Multiplier:</strong> A value that starts at 1.00 and incrementally increases as the flight progresses.</li>
                            <li><strong>Cash-out:</strong> The act of withdrawing winnings at a chosen multiplier value before the Flying Object flies away.</li>
                            <li><strong>Autoplay:</strong> A feature that enables players, in eligible jurisdictions, to automate their gameplay based on preset variables.</li>
                        </ul>
                        <h2>Skyward Games Rules</h2>
                        <ul>
                            <li><strong>Betting:</strong> Players place bets before the Flying Object takes off. Bets must be placed before the round starts or will be carried over to the next round.</li>
                            <li><strong>Multiplier Progression:</strong> The multiplier starts at 1.00 and increases as the Flying Object's flight progresses.</li>
                            <li><strong>Cash-out:</strong> Players aim to cash out at the right moment to maximize their winnings. The winning payout is calculated by multiplying the odds at the point of cash-out by the original bet amount. The gambling company may disable the cash-out feature at the beginning of the game, particularly when the multiplier is very low, to ensure that the payout does not fall below the initial pay-in amount, which could occur due to tax regulations in the respective player’s jurisdiction.</li>
                            <li><strong>Payout Considerations:</strong> Players should be aware that the winning amount may not be the exact result of multiplying the Multiplier and the placed bet, as the final amount could be subject to tax deductions based on the player’s jurisdiction.</li>
                        </ul>
                        <h2>Skyward Game Features</h2>
                        <ul>
                            <li>Exciting aircraft-themed crash gaming experience</li>
                            <li>Customized animations for a unique visual appeal</li>
                            <li>Ensured stability and minimal data consumption</li>
                            <li>Special bonus tools tailored for high-value players</li>
                            <li>Diverse betting zones and distinctive wagering choices</li>
                            <li>Engaging gameplay with frequent action and strategic cash-out options</li>
                            <li>Maximum multiplier reaching an impressive x100,000</li>
                        </ul>
                        <h2>Objective and Autoplay Feature in Skyward Games</h2>
                        <p><strong>Objective:</strong> The goal is to initiate a withdrawal before the Flying Object flies away. Failure to do so results in losing the bet.</p>
                        <p><strong>Autoplay Feature:</strong> Players can use the Autoplay feature to automate gameplay in eligible jurisdictions. They can set their bet size, define an automatic withdrawal threshold, and determine the number of rounds to be played.</p>
                        <p><strong>Autoplay Stop Conditions:</strong> Autoplay stops under the following conditions:</p>
                        <ul>
                            <li>Termination upon achieving a designated level of winnings, based on a predefined increase in the player's balance. The designated level is determined by players when they set up Autoplay.</li>
                            <li>Cessation if the player's balance experiences a predetermined level of losses. Players define this level during Autoplay setup.</li>
                            <li>Halt when the player accumulates a specified amount of winnings within a single round, as determined by the player's configuration in the Autoplay settings.</li>
                        </ul>
                        <h2>Why Choose Skyward Game at FUN88?</h2>
                        <ul>
                            <li><strong>Secure and Fair:</strong> FUN88 is committed to providing a secure and fair gaming environment. Enjoy peace of mind as you take to the skies in Skyward Crash.</li>
                            <li><strong>Mobile Compatibility:</strong> Play Skyward game anytime, anywhere! The game is optimized for mobile devices, allowing you to enjoy the thrill on the go.</li>
                            <li><strong>Exclusive Promotions:</strong> FUN88 offers exclusive <Link to={'/promotions'}>promotions and bonuses</Link> for Skyward Crash players. Keep an eye on our promotions page for special offers that enhance your gaming experience.</li>
                        </ul>
                        <p>Ready to elevate your gaming adventure? Download the <Link to={'/app-download'}>FUN88 mobile app</Link> now for on-the-go excitement with Skyward Game. Uncover exclusive rewards, <Link to={'/loyalty'}>VIP club</Link>, and thrilling gameplay that awaits you.  <Link to={'/signup'}>Register</Link>, today and soar to new heights with FUN88 – where every moment is a chance to win big!</p>
                        <h2>Frequently Asked Questions (FAQ) - Skyward Game</h2>

                        <h3>How do I start playing Skyward Game on FUN88?</h3>
                        <p>To begin your Skyward Game experience, simply register on the FUN88 platform, navigate to the game section, and launch Skyward. Choose your bet amount and take off for an adrenaline-pumping adventure!</p>
                        <h3>Is Skyward Game available on mobile devices?</h3>
                        <p>Absolutely! You can enjoy Skyward Game on the go by downloading the FUN88 mobile app. It's compatible with both iOS and Android devices, providing a seamless gaming experience.</p>
                        <h3>Are there any special rewards for Skyward Game players on FUN88?</h3>
                        <p>Yes! FUN88 offers exclusive rewards for Skyward Game enthusiasts. Keep an eye on the promotions page for special bonuses, free spins, and other exciting offers tailored for Skyward players.</p>
                        <h3>What benefits does the FUN88 VIP program offer for Skyward Game players?</h3>
                        <p>FUN88's VIP program brings a host of benefits for Skyward Game players, including personalized assistance, higher betting limits, and exclusive promotions. Elevate your gaming experience by becoming a FUN88 VIP member.</p>
                        <h3>How do I register on FUN88 to play Skyward Game?</h3>
                        <p>Registering on FUN88 is a breeze. Visit the FUN88 website, click on the 'Register' button, fill in the required details, and you'll be ready to explore the world of Skyward Game and other exciting offerings.</p>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(Skywardf88));