import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'
import Betgamesf88 from './Betgamesf88'
import BetgamesSEOYolo from './BetgamesSEOYolo'

type Props = {
    app:any
}

const BetgamesSEO = (props: Props) => {

    const { app :{brandId}}=props;

    const domain = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;
 
    
    return (
        <>
            {
            (brandId == 33 || brandId == 46) ? <></> :
            (brandId == 31 || brandId == 32) ? <BetgamesSEOYolo /> :
            (brandId == 47) ? <Betgamesf88 /> : 
            <BetgamesSEOYolo />
            }
        </>
    )
}

export default withTheme(withAppContext(BetgamesSEO));