import React from 'react'
import { BorderSec } from 'src/components/Bonus';
import { SvgIcon } from 'src/components/udb/commonStyle';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';


type Props = {}

const WelcomeBonusFun = (props: any) => {

    const { app: { brandId }, theme } = props;
    // const domainName = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : 'Betdaily' ;

    return (
        <>
            <MainWrapper>
                {/* <SvgIcon1>
                    <ScrolIcon />
                </SvgIcon1> */}
                <CommanBgWrapper>
                    <HeadText>
                        <p>Welcome to <strong>FUN88&#33;</strong> We&apos;re thrilled to have you join us, and as a token of our appreciation, we&apos;ve got an exciting gift for you.<br/>
                        Make your first deposit within 30 minutes of registration, and enjoy a staggering 388% bonus on your deposit amount&#33;<br/>
                        Missed the 30-minute window&#63; No worries—you&apos;ll still receive an incredible 288% bonus on your first deposit&#33;</p>
                    </HeadText>
                </CommanBgWrapper>
                <CommanBgWrapper className='halfsize'>
                    <WelcomeBonusTitle>
                        <h1>What is a Welcome Bonus&#63;</h1>
                        <BonusTitle>
                            <p>Welcome Bonus is our way of saying 'Thank You' for choosing FUN88.</p>
                        </BonusTitle>
                    </WelcomeBonusTitle>
                    <WelcomeBonusBody>
                        <p>Here&apos;s how you can claim your <strong>388%</strong> bonus on your initial deposit&#58;</p>
                        <StepsWrapper>
                            <StepsConatiner>
                                {/* <h1>Step 1:</h1> */}
                                <p> <span>Step 1&#58;</span> Register with Fun88.</p>
                            </StepsConatiner>
                            <StepsConatiner>
                              
                                <p> <span>Step 2&#58;</span> Deposit the amount of your choice.</p>
                            </StepsConatiner>
                            <StepsConatiner>
                                
                                <p> <span>Step 3&#58;</span> Now, you are eligible for 388% bonus (if you deposit within 30 minutes) or 288% bonus (if you deposit after 30 minutes).</p>
                            </StepsConatiner>
                            <StepsConatiner>

                                <p><span>Step 4&#58;</span> Complete the <strong>RO (Roll-Over) Requirement</strong> of <strong>34X</strong> your bonus amount.</p>
                            </StepsConatiner>
                            <StepsConatiner>
                  
                                <p><span>Step 5&#58;</span> The bonus will be automatically credited to your wallet.</p>
                            </StepsConatiner>
                        </StepsWrapper>
                    </WelcomeBonusBody>
                </CommanBgWrapper>
                <CommanBgWrapper  className='halfsize'>
                    <h1>Not sure what RO Requirement is&#63; Let us break it down for you.</h1>
                    <RoWrapper>
                        <h2>Example &#58;</h2>
                        <ul>
                            <li><span>Your First Deposit&#58;</span> ₹1,000</li>
                            <li><span>Bonus (388%)&#58;</span> ₹3,880</li>
                            <li><span>RO Requirement&#58;</span> 34X the bonus amount, which means ₹3,880 * 34 = ₹131,920</li>
                        </ul>
                        <p>Once you reach the RO requirement of ₹131,920, your bonus of ₹3,880 will be instantly added to your wallet!</p>
                        <PLWrap>
                            <PlLeft>
                                <h5>
                                    | Profit & Loss |
                                </h5>
                                <BorderSec></BorderSec>
                                <h5>Total Stake</h5>
                            </PlLeft>

                            <PlRight>
                                <h5><b>≥ 60%</b></h5>
                            </PlRight>
                        </PLWrap>
                        <p><strong>In other words, bets must be with odds of at least Decimal 1.60 to be contributed towards bonus rollover requirements.</strong></p>
                        <p>So here is an example: you placed a bet of ₹100 in Roulette on black color. Your stake is: ₹100.</p>
                    </RoWrapper>
                    <WelcomeBonusBody>
                        <h2>Now there are 3 scenarios:</h2>
                        <ProfitAnddLossWrapper>
                            <PandLConatiner>
                                <StepsContent>
                                    <p><span>a)</span> You win ₹100. Your Profit & Loss = ₹100</p>
                                    <p>The valid RO = (|100|/100) = 100%</p>
                                    <p>Hence, your valid RO = ₹100</p>
                                </StepsContent>
                            </PandLConatiner>
                            <HorizontalDashLine></HorizontalDashLine>
                            <PandLConatiner>
                                <StepsContent>
                                    <p><span>b)</span> You lose ₹100. Your Profit & Loss = - ₹100</p>
                                    <p>The valid RO = (|-100|/100) = 100%</p>
                                    <p>Hence, your valid RO = ₹100</p>
                                </StepsContent>
                            </PandLConatiner>
                            <HorizontalDashLine></HorizontalDashLine>
                            <PandLConatiner>
                                <StepsContent>
                                    <p><span>c)</span> You win ₹5 and your Profit & Loss = ₹5</p>
                                    <p>The valid RO = (|5|/100) = 5%</p>
                                    <p>{"Hence, your valid RO = 0 as the 5% < 60%."}</p>
                                </StepsContent>
                            </PandLConatiner>
                        </ProfitAnddLossWrapper>
                    </WelcomeBonusBody>
                </CommanBgWrapper>
                <CommanBgWrapper  className='halfsize'>
                    <TermsAndCondition>
                        <h1>Terms and conditions:</h1>

                        <ol>
                            <li>This promotion is valid only for newly registered players making their first successful deposit.</li>
                            <li>The bonus amount will be credited to your Main Wallet after completing the turnover requirements.</li>
                            <li>The maximum bonus amount is capped at <strong>₹28,000</strong>.</li>
                            <li>This promotion is limited to one-use per player.</li>
                            <li>The bonus amount must be rolled over <strong>34 times</strong>. Turnover generated across any part of the platform counts toward the turnover requirement.</li>
                            <li>Turnover requirements must be fulfilled within <strong>7 days</strong> of your first deposit. If not met within this period, the bonus will expire.</li>
                            <li><strong>Turnover Calculation:</strong> Turnover is considered as 'Valid Turnover,' determined by the following formula: The ratio of your absolute Profit & Loss to the total stake must be <strong>≥ 60%</strong>. Valid turnover is calculated per 'market' for sports and per 'round' for casinos. Multiple bets can be placed in a single market or round, but the Profit & Loss-to-stake ratio must meet the threshold. In exchanges, match odds provided by Betfair and other bookmakers will be considered as one market.</li>
                            <li>Void, cancelled, drawn, or cashed-out bets do not count towards deposit or bonus turnover requirements.</li>
                            <li>Multiple accounts will not qualify for this promotion. All players are subject to duplicate checks, and FUN88 reserves the right to disqualify any player found engaging in collusion or foul play.</li>
                            <li>This promotion cannot be combined with, or used to qualify for, any other promotions.</li>
                            <li>FUN88 reserves the right to amend, cancel, reclaim, or refuse this promotion at its discretion.</li>
                            <li>The bonus amount is non-withdrawable, but winnings derived from the bonus can be withdrawn.</li>
                            <li>In the event of any identified fraudulent activity, FUN88 reserves the right to withhold funds and reject withdrawal requests.</li>
                            <li>FUN88's General Terms & Conditions apply</li>
                        </ol>
                    </TermsAndCondition>
                </CommanBgWrapper>
            </MainWrapper>
            {/* <FooterContent /> */}
        </>
    )
}

export default withAppContext(withTheme(WelcomeBonusFun));

//Styled Components

const HorizontalDashLine = styled.div`
    width: 100%;
    border: 1px solid #c0bdbd;
    border-style: dashed;
    margin-top: 9px;
`;

export const SvgIcon1 = styled(SvgIcon)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const FlexBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    /* padding-bottom: 0; */
    position: relative;
    background: #EBEBEB;
    gap: 9px;
    .halfsize{
        width: 50%;
    }
`;

export const CommanBgWrapper = styled(FlexBox)`
    border-radius: 9.945px;
    background: #FFF;
    padding: 1.2rem;

    h1 {
        color: #06A6FF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 19.236px */
    }
`;

export const HeadText = styled(FlexBox)`

    p {
        color: #444;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%;
    }
`;

export const WelcomeBonusTitle = styled(FlexBox)`

    h1 {
        color: #06A6FF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p {
        color: #444;
        text-align: justify;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 145.455% */
    }
`;

export const BonusTitle = styled(FlexBox)`
    /* padding-top: 1em; */

    p {
        color: #444;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 900;
        line-height: 160.3%; /* 17.633px */
        margin-bottom: 0;
    }
`;

export const WelcomeBonusBody = styled(FlexBox)`
    padding: 5px 2em;

    h2 {
        width: 100%;
        text-align: left;
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 17.633px */
        margin: 0;
    }

    p {
        color: #444;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.3%; /* 17.633px */
        margin-bottom: 0;
    }
`;

export const StepsWrapper = styled(FlexBox)`
    padding-top: 8px;
    width: 60%;
`;

export const ProfitAnddLossWrapper = styled(FlexBox)`
    padding: 8px;
`;

export const StepsConatiner = styled(FlexBox)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3px;
    font-size: 12px;
    line-height: 160.3%; /* 17.633px */
    font-style: normal;
    padding-top: 8px;

    h1 {
        flex-shrink: 0;
        color: #444;
        font-weight: 600;
        font-size: 12px;
        text-align: left;
    }

    p {
        flex-grow: 1;
        color: #444;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 0;
    }

    span{
        flex-shrink: 0;
        color: #444;
        font-weight: 600;
        font-size: 12px;
        text-align: left;
    }
`;

export const PandLConatiner = styled(StepsConatiner)`
    h1 {
        font-weight: 500;
    }
    span {
        color: #444 !important;
        display: inline;
        text-transform: lowercase;
        font-weight: 600;
    }
`;

export const StepsContent = styled(FlexBox)`
    align-items: flex-start;
        p {
            text-align: left;
        flex-grow: 1;
        color: #444;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        margin: 0;
    }
`;

export const RoWrapper = styled(FlexBox)`
    align-items: flex-start;
    padding-left: 1em;

    h2 {
        padding-top: 5px;
        color: #06A6FF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 19.236px */
    }

    ul {
        padding: 0 0 0 0.8em;
        margin: 0;
    }

    ul li span {
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%;
        display: inline;

    }

    ul li {
        color: #444 !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.3%;  
    }

    p {
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.3%; /* 17.633px */
        padding: 0.6em 0;
        margin: 0;
    }
`;

export const PLWrap = styled.div`
    padding: 1.5em;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: #444;;

    h5 {
        font-weight: 500;
    }
`;

export const PlLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export  const PlRight = styled.div`
    font-weight: 700;
`;

export const TermsAndCondition = styled(FlexBox)`
    h1 {
        padding-bottom: 0.5em;
        color: #06A6FF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 19.236px */
    }

    ol {
        padding: 0 0 0 0.8em;
        margin: 0;
    }

    ol li {
        color: #444;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.3%; /* 17.633px */
        margin-bottom: 5px;
    }
`;